import React from 'react'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Price.scss'
import { ProductListData } from 'Utils/api/gql/types'
import { isDiscounted, getCurrentPrice, getOriginalPrice, isPersonallyDiscounted } from 'Utils/helpers/productAttributeHelper'
import { numberFormatter } from 'Utils/helpers/formatters'

type PropType = {
  className?: string
  horizontal?: boolean
  product: ProductListData
  rotated?: boolean
  solid?: boolean
}

// TODO: Change every Price to DisplayPrice* and delete this (and connecting index.ts).
const Price: React.SFC<PropType> = (props) => {
  const {
    children,
    className,
    horizontal,
    product,
    rotated,
    solid,
    ...otherProps
  } = props
  const classNames = `${cl.priceContainer} ${className || ''} ${rotated ? cl.priceContainerIsRotated : ''} ${solid ? cl.priceContainerIsSolid : ''}`.trim()

  const originalPrice = getOriginalPrice(product)
  const price = getCurrentPrice(product)
  const unit = product.default_quantity_name

  return (
    <div className={classNames} {...otherProps}>
      <div className={cn(cg.gridX, cg.alignMiddle)}>
        <div className={`${cg.cell} ${horizontal ? cg.shrink : ''} ${cl.delContainer}`}></div>
        <div className={cn(cg.cell, cl.priceContent, {[cg.shrink]: horizontal, [cl.personalPrice]: isPersonallyDiscounted(product), [cl.hasOldPrice]: !!originalPrice })}>
          {/* TODO: dangerouslySetInnerHTML? */}
          <span className={cl.price} >{numberFormatter(price)} Ft / {unit}</span>
        </div>

        {children && <div className={cn(cg.cell, cl.priceInfo)}>{children}</div>}
      </div>
    </div>
  )
}

export default Price
