import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import cg from 'Scss/app.scss'

type PropTypes = {
  gridCols: {
    small?: number,
    medium?: number,
    large?: number,
    xlarge?: number
  },
  view?: 'grid' | 'list'
}

const ProductList: React.SFC<PropTypes> = (props) => {
  const { children, gridCols } = props
  const view = props.view || 'grid'

  const gridClassName = `${gridCols.small ? cg[`small-up-${gridCols.small}`] : ''} ${gridCols.medium ? cg[`medium-up-${gridCols.medium}`] : ''} ${gridCols.large ? cg[`large-up-${gridCols.large}`] : ''} ${gridCols.xlarge ? cg[`xlarge-up-${gridCols.xlarge}`] : ''}`.trim()

  return (
    <div>
      <div className={`${cg.gridX} ${cg.gridMarginX} ${view === 'grid' ? gridClassName : ''}`}>
        {children || <div className={cn(cg.cell)}>TODO: no children</div>}
      </div>
    </div>
  )
}

export default ProductList