import PageLink from "./PageLink"
import { ProductListData, CartFragment_cart_products_product } from "Utils/api/gql/types"

const ProductLink: React.SFC<{ product: Pick<ProductListData | CartFragment_cart_products_product, 'smarturl' | 'url_key'>, onClick?: () => void }> =
    ({ product, children, onClick }) =>
        <PageLink
            as={`/termekek/${product.url_key || product.smarturl}`}
            toPage={{
                pathname: "/product",
                query: { url_key: product.url_key || product.smarturl }
            }}
            onClick={onClick}
        >
            {children}
        </PageLink>

export default ProductLink
