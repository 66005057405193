import gql from 'graphql-tag'
import ProductAllData from '../fragments/ProductAllData'

export default gql`
query product($getBy: getProductBy!, $value: String!) {
    product(getBy: $getBy, value: $value) {
        ...ProductAllData
    }
}
${ProductAllData}
`
