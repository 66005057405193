import gql from 'graphql-tag'

export default gql`
fragment PaginatorInfoFragment on PaginatorInfo {
  count
  currentPage
  firstItem
  hasMorePages
  lastItem
  lastPage
  perPage
  total
}
`
