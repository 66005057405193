import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import cl from './CategoryList.scss'
import ScrollableList from 'Components/ScrollableList/ScrollableList'

type PropTypes = {
  isOpenedCartPanel: boolean
  children: JSX.Element[]
}
  
const CategoryList: React.SFC<PropTypes> = ({ children, isOpenedCartPanel }: PropTypes) => 
  <div className={cn(cl.categoryList)} id="categoryList">
    <ScrollableList 
      listItemContainerClassName={`${cl.scrollabeContainer} ${isOpenedCartPanel === true ? cl.quarter : cl.quint}`}
      listItemsContent={children}
      listItemNoBorders
      listItemNoMargin
      containerNoMargin
      alwaysShowArrows
      productList
    />
  </div>

export default CategoryList