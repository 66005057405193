import React from 'react'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './ProductRangeBanner.scss'
import { productCounts_bannerProductCount } from 'Utils/api/gql/types'
import PageLink from 'Components/PageLink'

interface PropType {
  productCounts: Pick<productCounts_bannerProductCount, 'all' | 'mpl'>
}

const ProductRangeBanner: React.SFC<PropType> = (props: PropType) => {
  return (
    <span className={cn(cl.banner)}>
        <div className={cn(cl.bannerDataContainer)}>
          <div className={cn(cg.gridX, cl.gridX)}>
            <div className={cn(cg.cell, cl.illustration)}>
              <img src="/static/illustration/groby_termek_valasztek.png" width="300" height="auto" />
            </div>
            <div className={cn(cg.cell, cg.auto)}>
              <div className={cn(cl.bannerDataName)}>
                Óriási termékválaszték
              </div>
              {(props.productCounts.all && props.productCounts.mpl)
                ? <div className={cn(cl.bannerDataInfo)}>
                    <strong>Jelenleg {props.productCounts.all} féle terméket találsz nálunk!</strong>
                  </div>
                : null
              }
            </div>
          </div>
        </div>
    </span>
  )
}

export default ProductRangeBanner
