const urlMapper = (entry: string) => ({ thumbnail: `${entry}_tn.jpg`, main: `${entry}.JPG` })

export const stores: Array<Webshop.IStoreData> = [
    {
        id: 'buda',
        name: 'GRoby Buda',
        address: '1027 Budapest, Margit krt. 48.',
        details: '(Margit krt. és Szász Károly u. sarka)',
        location: 'Budapest, Szász Károly u. 7, 1027 Magyarország',
        coordinates: {
            lat: 47.510744,
            lng: 19.032664
        },
        opening_hours: [
            {
                label: 'Hétfő - Péntek',
                value: '6:30 - 21:00'
            },
            {
                label: 'Szombat',
                value: '7:00 - 19:00'
            },
            {
                label: 'Vasárnap',
                value: '8:00 - 14:00'
            }
        ],
        phone: '+361 225-16-33',
        email: 'buda@groby.hu',
        gallery: [
            '/static/gallery/stores/buda/DSC_3825',
            '/static/gallery/stores/buda/DSC_3828',
            '/static/gallery/stores/buda/DSC_3830',
            '/static/gallery/stores/buda/DSC_3833',
            '/static/gallery/stores/buda/DSC_3853'
        ].map(urlMapper)
    },
    {
        id: 'jozsefvaros',
        name: 'GRoby Józsefváros',
        address: '1085 Budapest, Krúdy u. 20.',
        details: '(Rökk Szilárd utca és Krúdy Gyula u. sarka)',
        location: '1085 Budapest, Krúdy utca 20, HU',
        coordinates: {
            lat: 47.490545,
            lng: 19.069958
        },
        opening_hours: [
            {
                label: 'Hétfő - Péntek',
                value: '6:00 - 21:00'
            },
            {
                label: 'Szombat',
                value: '7:00 - 19:00'
            },
            {
                label: 'Vasárnap',
                value: '8:00 - 14:00'
            }
        ],
        phone: '+361 429-02-25',
        email: 'jozsef@groby.hu',
        gallery: [
            '/static/gallery/stores/jozsefvaros/DSC_3691',
            '/static/gallery/stores/jozsefvaros/DSC_3704',
            '/static/gallery/stores/jozsefvaros/DSC_3706',
            '/static/gallery/stores/jozsefvaros/DSC_3709',
            '/static/gallery/stores/jozsefvaros/DSC_3711',
            '/static/gallery/stores/jozsefvaros/DSC_3719'
        ].map(urlMapper)
    },
    {
        id: 'teleki',
        name: 'GRoby Teleki',
        address: '1086 Budapest, Teleki tér 1-3',
        details: '(Teleki László tér és Erdélyi u. sarka)',
        location: '1086 Budapest, Teleki László tér 1-3., HU',
        coordinates: {
            lat: 47.493166,
            lng: 19.08241
        },
        opening_hours: [
            {
                label: 'Hétfő - Péntek',
                value: '6:00 - 21:00'
            },
            {
                label: 'Szombat',
                value: '7:00 - 19:00'
            },
            {
                label: 'Vasárnap',
                value: '8:00 - 14:00'
            }
        ],
        phone: '+361 455-01-83',
        email: 'teleki@groby.hu',
        gallery: [
            '/static/gallery/stores/teleki/DSC_3734',
            '/static/gallery/stores/teleki/DSC_3735',
            '/static/gallery/stores/teleki/DSC_3751',
            '/static/gallery/stores/teleki/DSC_3769',
            '/static/gallery/stores/teleki/DSC_3772'
        ].map(urlMapper)
    },
    {
        id: 'moricz',
        name: 'GRoby Móricz',
        address: '1119 Budapest, Villányi út 1',
        details: '(Móricz Zsigmond körtérnél)',
        location: '1119 Budapest, Villányi út 1, HU',
        coordinates: {
            lat: 47.47801,
            lng: 19.046306
        },
        opening_hours: [
            {
                label: 'Hétfő - Péntek',
                value: '6:30 - 21:00'
            },
            {
                label: 'Szombat',
                value: '7:00 - 19:00'
            },
            {
                label: 'Vasárnap',
                value: '8:00 - 14:00'
            }
        ],
        phone: '+361 209-92-50',
        email: 'moricz@groby.hu',
        gallery: [
            '/static/gallery/stores/moricz/DSC_3567',
            '/static/gallery/stores/moricz/DSC_3568',
            '/static/gallery/stores/moricz/DSC_3572',
            '/static/gallery/stores/moricz/DSC_3579',
            '/static/gallery/stores/moricz/DSC_3647'
        ].map(urlMapper)
    },
     {
         id: 'gold',
         name: 'GRoby GOLD',
         address: '1067 Budapest, Teréz krt. 23.',
         details: '',
         location: '47.50652329167983, 19.06199598184381',
         coordinates: {
             lat: 47.50652329167983,
             lng: 19.06199598184381
         },
         opening_hours: [
             {
                 label: 'Hétfő - Péntek',
                 value: '6:30 - 21:00'
             },
             {
                 label: 'Szombat',
                 value: '8:00 - 21:00'
             },
             {
                 label: 'Vasárnap',
                 value: '8:00 - 14:00'
             }
         ],
         phone: '+361 476-36-87',
         email: 'gold@groby.hu',
         gallery: [
             '/static/gallery/stores/gold/1683541329117',
             '/static/gallery/stores/gold/IMG_1438',
             '/static/gallery/stores/gold/IMG_1445',
             '/static/gallery/stores/gold/IMG_1453',
             '/static/gallery/stores/gold/IMG_1463',
             '/static/gallery/stores/gold/IMG_1469',
             '/static/gallery/stores/gold/IMG_1484',
             '/static/gallery/stores/gold/IMG_1487',
             '/static/gallery/stores/gold/IMG_1509',
            ].map(urlMapper)
     }
]


export const contactStores: Webshop.IStoreData[] = [
    {
        id: 'szekhely',
        name: 'GRoby NETSHOP Kft',
        address: '1186 Budapest, Közdűlő út 46-50.',
        details: '(Ipacsfa u. sarok)',
        noLink: true,
        location: '1186 Budapest, Közdűlő út 46-50.',
        coordinates: {
            lat: 47.424908,
            lng: 19.171479
        },
        opening_hours: [
            {
                label: 'Hétfő - Péntek',
                value: '8:00 - 18:00'
            },
            {
                label: 'Szombat',
                value: '8:00 - 12:00'
            },
            {
                label: 'Vasárnap',
                value: 'Zárva'
            }
        ],
        phone: '+361 476-3688',
        email: 'e-shop@groby.hu',
        gallery: []
    },
]
