import React from 'react'
import cn from 'classnames'
import cg from 'Scss/app.scss'
import { SVGIconDanger, SVGProfileDark } from 'Components/SVGCollection';

type PropType = {
    message: string
    errorType?: Redux.IFormGeneralErrorType
}

function FormError(props: PropType) {
    const {errorType} = props
    return (
        <span className={cn(cg.gridX, cg.errorMessage, {[cg.inverseError]: errorType == "inverse"})}>
            <span className={cn(cg.cell, cg.shrink)} aria-hidden={true}>
                {errorType == "inverse" ? (
                    <SVGProfileDark width={18} height={18} />
                ):(
                    <SVGIconDanger width={18} height={18} />
                )}
            </span>

            <span className={cn(cg.cell, cg.auto)}>
                {props.message}
            </span>
        </span>
    )
}

export default FormError
