import React from 'react'
import PageLink from 'Components/PageLink'
import { SVGArrow, SVGShopsIcon } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Shops.scss'

import { stores } from 'Utils/data/stores'

export default function Shops(props: { isInHomePage?: boolean; }) {
  const { isInHomePage } = props

  return (
    <div className={cn(cl.shops, isInHomePage ? cl.shopsIsInHomePage : {})}>
      {isInHomePage
        ? <React.Fragment>
          <h3>
            <PageLink toPage="/uzletek">
              Üzleteink
            </PageLink>
          </h3>
        </React.Fragment>
        : <h3 className={cg.showForMedium}>Üzleteink</h3>
      }

      {stores.map((store, index) => (
        <PageLink toPage={`/uzletek/${store.id}`} key={`shop_${index}`} className={cn(cl.shopsContent)}>
          <div className={cn(cg.gridX, cg.alignMiddle)}>
            <div className={cn(cg.cell, cg.auto)}>
              <dl className={cn(cg.gridX, cg.gridMarginX, cg.alignMiddle)}>
                <dt className={cn(cg.cell, cg.medium5)}>
                    {store.name}
                </dt>
                <dd className={cn(cg.cell, cg.medium7)}>
                  {store.address}
                </dd>
              </dl>
            </div>
            <div className={cn(cg.cell, cg.shrink)}>
                <span className={cg.showForSr}>
                  {store.name}
                </span>
                <span aria-hidden={true}>
                  <SVGArrow width={24} height={24} />
                </span>
            </div>
          </div>
        </PageLink>
      ))}

      <div className={cn(cl.iconWrapper, isInHomePage ? {} : cg.showForMedium)} aria-hidden={true}>
        <SVGShopsIcon width="36" height="36" />
      </div>
    </div>
  )
}
