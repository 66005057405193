import React from 'react'
import PageLink from 'Components/PageLink'
import Button from 'Components/Button'
import ProductList from 'Components/ProductList/ProductList'
import CartListProductCard from 'Components/CartListProductCard'

import { SVGIconBasket, SVGIconDanger, SVGArrowNext } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './BasketBar.scss'
import btn from 'Components/Button/Button.scss'
import { connect, DispatchProp } from 'react-redux'
import appBar from 'Components/AppBar/AppBar.scss'
import { toggleCart } from 'Utils/redux/actions'
import { CartFragment, CartFragment_cart_products } from 'Utils/api/gql/types'
import { numberFormatter } from 'Utils/helpers/formatters'
import CartHelper from 'Utils/helpers/cartHelper'
import { pushRoute } from 'Utils/helpers/routerHelper'

type StateProps = {
  cart: CartFragment | null
}

type OwnProps = {
  className: string
}

type PropType = StateProps & OwnProps & DispatchProp

class BasketBar extends React.Component<PropType> {
  productListRef?: HTMLDivElement | null

  componentDidUpdate(prevProps: PropType) {
      if (
        this.productListRef &&
        prevProps.cart &&
        this.props.cart &&
        this.props.cart.cart_products.length > prevProps.cart.cart_products.length
      ) {
        this.productListRef.scrollTop = 0
      }
  }

  renderProduct(item: CartFragment_cart_products) {
      return (
        <div className={cn(cg.cell, cl.product)} key={item.product.id}>
          <CartListProductCard
            cartItem={item}
          />
        </div>
      )
  }

  renderEmpties(item: Webshop.EmptiesCartProduct) {
    return (
      <div className={cn(cg.cell, cl.product)} key={item.empties.id}>
        <CartListProductCard
          cartItem={item}
        />
      </div>
    )
}

  renderEmptyCart() {
    return (
      <div className={cn(cl.emptyBasket, appBar.basketContainer)}>
        <div className={cn(cl.emptyContainer, cg.textCenter)}>
          <SVGIconBasket width={80} height={80} style={{ color: '#00a3e0' }} />
          <p>A kosarad jelenleg üres.</p>
          <p>Adj hozzá termékeket!</p>
        </div>
      </div>
    )
  }

  handleCloseClick = () => {
    this.props.dispatch(toggleCart())
  }

  renderBody() {
    if (this.props.cart) {
      const cartProducts = [...this.props.cart.cart_products].reverse()
      const empties = CartHelper.aggregateEmptiesFromCartProducts(cartProducts)

      return (
        <div className={cn(cl.basketContainer, appBar.basketContainer)} ref={(plr) => { this.productListRef = plr }}>
          <ProductList gridCols={{ small: 1 }} view="list">
            {cartProducts.map(item => this.renderProduct(item))}
            {empties.map(item => this.renderEmpties(item))}
          </ProductList>
        </div>
      )
    }
  }

  render() {
    if (!this.props.cart) {
      return null
    }
    const isEmpty = this.props.cart.cart_products.length === 0

    return (
      <div className={`${cl.basketBar} ${appBar.basketBar} ${this.props.className || ''}`}>
        <Button
          label="Kosár tartalma"
          className={cn(btn.blank, cl.cartCloser)}
          disabled={false}
          renderIcon={{ alignIcon: null, ariaLabel: 'ariaLabel', Icon: <SVGArrowNext width={24} height={24} /> }}
          onClick={this.handleCloseClick}
        />

        {!isEmpty && this.renderBody()}

        {isEmpty && this.renderEmptyCart()}

        <div className={cn(cl.totalPrice)}>
          <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>
            <div className={cn(cg.cell, cg.small6)}>
              ÖSSZESEN
            </div>
            <div className={cn(cg.cell, cg.small6, cg.textRight)}>
              <strong>{numberFormatter(CartHelper.getCartTotalPrice(this.props.cart))}&nbsp;Ft</strong>
            </div>
          </div>
        </div>
        <div className={cn(cl.alertInformation)}>
          <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>
            <div className={cn(cg.cell, cg.shrink)}>
              <SVGIconDanger width={22} height={22} />
            </div>
            <div className={cn(cg.cell, cg.auto)}>
              <p>
                <span>A végösszeg a szállítás költségét, illetve MPL szállítás esetén a csomagolási költséget nem tartalmazza.</span>

                <PageLink toPage="/szallitas">
                  További információ
                </PageLink>
              </p>
            </div>
          </div>
        </div>
        <Button
          label="Megrendelés"
          className={cn(btn.primary, btn.expanded)}
          onClick={() => { pushRoute('/kosar') }}
          disabled={isEmpty}
        />
      </div>
    )
  }
}


function mapToStateProps({ cart }: Redux.IReduxState): StateProps  {
  return { cart }
}

export default connect(mapToStateProps)(BasketBar);
