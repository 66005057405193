import React from 'react'
import { Sticky } from 'react-sticky'
import BasketBar from './BasketBar'
import cn from 'classnames'
import cg from 'Scss/app.scss'
import appBar from 'Components/AppBar/AppBar.scss'
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter'
import { connect } from 'react-redux'

type PropType = {
    isOpenedCartPanel: boolean
}

const CARTPANEL_ANIM_TIMEOUT = 400

class CartPanel extends React.Component<PropType> {
    panelDiv: HTMLDivElement | null = null
    transitionEndEmitNeeded = false

    emitIfNeeded = () => {
        if (this.transitionEndEmitNeeded) {
            this.transitionEndEmitNeeded = false
            uiEventEmitter.emit(UIEvents.CARTPANEL_TRANSITION_END)
        }
    }

    componentDidMount () {
        if (this.panelDiv) {
            this.panelDiv.addEventListener('transitionend', this.emitIfNeeded)
        }
    }

    componentWillUnmount() {
        if (this.panelDiv) {
            this.panelDiv.removeEventListener('transitionend', this.emitIfNeeded)
        }
    }

    componentDidUpdate (prevProps: PropType) {
        if (prevProps.isOpenedCartPanel !== this.props.isOpenedCartPanel) {
            uiEventEmitter.emit(UIEvents.CARTPANEL_TRANSITION_START)
            this.transitionEndEmitNeeded = true
            setTimeout(this.emitIfNeeded, CARTPANEL_ANIM_TIMEOUT)  // lots of browsers don't support transitionend event yet...
        }
    }

    render () {
        return (
            <div ref={ref => this.panelDiv = ref} className={`${cg.cartPanel} ${cg.mediumOrder2} ${this.props.isOpenedCartPanel === true ? cg.opened : ''}`} id="cartPanel">
                <Sticky topOffset={-130}>
                    {({ style }) => (
                    <div className={cn(cg.defaultFixed)} style={style}>
                        <BasketBar className={cn(appBar.basketBar)} />
                    </div>
                    )}
                </Sticky>
            </div>
        )
    }
}

export default connect(({ isOpenedCartPanel }: Redux.IReduxState): PropType => ({
    isOpenedCartPanel
}))(CartPanel)
