import React, { HTMLAttributes } from 'react'
import { StickyContainer } from 'react-sticky';
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter';

type StickyContainerType = React.Component<HTMLAttributes<HTMLDivElement>> & { notifySubscribers: (evt: any) => void }

export class StickyContainerFix extends React.Component {
    stickyRef: StickyContainerType | null = null
    componentDidMount () {
        this.tryFix()
        requestAnimationFrame(this.tryFix)
        uiEventEmitter.on(UIEvents.REPOSITION_BASKETBAR, this.onDocumentHeightChange)
    }
    onDocumentHeightChange = () => {
        requestAnimationFrame(this.tryFix)
    }
    componentWillUnmount() {
        uiEventEmitter.off(UIEvents.REPOSITION_BASKETBAR, this.onDocumentHeightChange)
    }
    tryFix = () => {
        this.stickyRef && this.stickyRef.notifySubscribers({ currentTarget: window })
    }
    render = () => (
        <StickyContainer
            children={this.props.children}
            ref={ref => this.stickyRef = ref as StickyContainerType | null}
        />
    )
}
