import React, { MouseEvent, useContext, createContext } from 'react'
import { SVGArrow } from 'Components/SVGCollection'

import cn from 'classnames'
import cl from './ScrollableList.scss'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce';

// Tweak this number if arrows appear when they shouldn't
const MAGIC_BORDER_CORRECTION_PX = 12

export const WasDisplayedIndexContext = createContext<number>(0);
WasDisplayedIndexContext.displayName = "WasDisplayedContext";

type PropType = {
  listItemsContent?: React.ReactNode
  listItemContainerClassName?: string
  listItemNoBorders?: boolean
  listItemNoMargin?: boolean
  alwaysShowArrows?: boolean
  containerNoMargin?: boolean
  productList?: boolean
  mainContent?: React.ReactNode
  isFetching?: boolean
  displayed?: number
}

type StateType = {
  selectedImageId: number | null
  galleryWidth: number
  listItemsScrollWidth: number
  id: number
}

class ScrollableList extends React.Component<PropType, StateType> {
   state: StateType = {
    selectedImageId: null,
    galleryWidth: 0,
    listItemsScrollWidth: 0,
    id: 0
  }

  disableScrollHandler = false
  lastTime: number = 0
  lastScrollX = 0

  list: HTMLDivElement | null = null

  get galleryId () {
    return `gallery_${this.state.id}`
  }

  get listItemsId () {
    return `listItems_${this.state.id}`
  }

  setSizes = () => {
    const gallery = document.querySelector(`div#${this.galleryId}`) as HTMLDivElement
    const listItems = document.querySelector(`div#${this.listItemsId}`)
    this.elemWidth = null
    this.setState({
      galleryWidth: (gallery && gallery.offsetWidth) || 0,
      listItemsScrollWidth: (listItems && listItems.scrollWidth) || 0
    })
  }

  throttledSetSizes = throttle(this.setSizes, 100)

  componentDidMount () {
    this.setState({
      id: Math.floor(Math.random() * 100000000)
    }, this.setSizes)
    window.addEventListener('resize', this.throttledSetSizes)
  }

  componentDidUpdate (prevProps: PropType, prevState: StateType) {
    if (prevState.id !== this.state.id || this.props !== prevProps) {
      this.setSizes()
    }
    if (this.props.isFetching !== prevProps.isFetching) {
      this.setSizes()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledSetSizes)
    if (this.touchTimeout !== null) {
      window.clearTimeout(this.touchTimeout)
    }
  }

  elemWidth: number | null = null
  getFirstElementWidth (): number {
    if (this.elemWidth) {
      return this.elemWidth
    }
    const firstElement = document.querySelector(`div#${this.listItemsId} :first-child`) as HTMLDivElement
    if (firstElement) {
      const style = window.getComputedStyle ? getComputedStyle(firstElement, null) : firstElement.currentStyle;
      const marginLeft = parseInt(style.marginLeft || '0', 10);
      const marginRight = parseInt(style.marginRight || '0', 10);

      this.elemWidth = firstElement.offsetWidth + marginLeft + marginRight
    } else {
      this.elemWidth = 0
    }

    return this.elemWidth
  }

  obscuredWidth (state: StateType) {
    return state.listItemsScrollWidth - state.galleryWidth - MAGIC_BORDER_CORRECTION_PX
  }

  throttledForceUpdate = throttle(() => this.forceUpdate(), 100)

  get x() {
    return this.list
      ? this.list.scrollLeft
      : this.lastScrollX
  }

  get shouldRenderPrev () {
    return this.canScrollPrev || this.props.alwaysShowArrows
  }

  get canScrollPrev () {
    return this.x > 0
  }

  get shouldRenderNext () {
    return this.canScrollNext || this.props.alwaysShowArrows
  }

  get canScrollNext () {
    return this.x < this.obscuredWidth(this.state)
  }

  currItemId = 0
  scrollPosInItemId = () => {
    return Math.floor((this.x+5) / this.getFirstElementWidth())
  }

  goToNearest = (dir: number) => {
    const x = this.x

    const itemWidth = this.getFirstElementWidth()
    const rounded = Math.round(x / itemWidth) * itemWidth
    const ceiled = Math.ceil(x / itemWidth) * itemWidth
    const floored = Math.floor(x / itemWidth) * itemWidth
    const diffCeiled = Math.abs(x - ceiled)
    const diffFloored = Math.abs(x - floored)
    const nextScrollX = dir === 0
      ? rounded
      : dir > 0  && diffCeiled/diffFloored < 3.5 || diffFloored/diffCeiled > 3.5
        ? Math.ceil(x / itemWidth) * itemWidth
        : Math.floor(x / itemWidth) * itemWidth

    this.animateScroll(nextScrollX)
  }

  goToNearestTimeout: number | null = null
  invokeGoToNearest = (dir: number, timeout = 150) => {
    if (this.goToNearestTimeout === null) {
      this.goToNearestTimeout = window.setTimeout(() => {
        this.goToNearest(dir)
        this.goToNearestTimeout = null
      }, timeout)
    }
  }

  cancelGoToNearest = () => {
    if (this.goToNearestTimeout !== null) {
      window.clearTimeout(this.goToNearestTimeout)
      this.goToNearestTimeout = null
    }
  }

  // animation position curve (t: [0,1]) -> [0,1]
  f = (t: number) => {
    t = t * 2.09545
    return (-(t**3)/3 + t**2 + 0.2*t) / 1.743
  }

  animDuration = 200
  animStartX: number | null = null
  animStartTime: number | null = null
  animTargetX: number | null = null

  animateScroll = (animTargetX?: number) => {
    if (!this.list) return

    const now = performance.now()
    if (typeof animTargetX === 'number') {
      this.animStartX = this.list.scrollLeft
      this.animTargetX = animTargetX
      this.animStartTime = now
      this.animDuration = 250

      this.list.style.overflow = 'hidden' // stop momentum scroll
      setTimeout(() => { if (this.list) { this.list.style.overflow = '' } }, 10)

      if (Math.abs(this.animStartX - animTargetX) < 3) {
        this.list.scrollLeft = animTargetX
        return
      }

      this.disableScrollHandler = true
      requestAnimationFrame(() => this.animateScroll())

      return
    }
    if (this.animTargetX === null || this.animStartTime === null || this.animStartX === null) {
      return
    }

    const t = (now - this.animStartTime) / this.animDuration
    const x = this.f(t) * (this.animTargetX - this.animStartX) + this.animStartX

    // console.log(`animateScroll x: ${x}`)

    if (t < 1) {
      this.disableScrollHandler = true
      this.list.scrollLeft = x
      requestAnimationFrame(() => this.animateScroll())
    } else {
      this.list.scrollLeft = this.animTargetX
      this.lastScrollX = this.animTargetX
      this.currItemId = this.scrollPosInItemId()
      this.resetScrollAnimation()
      this.throttledForceUpdate()
    }
  }

  resetScrollAnimation = () => {
    this.animStartTime = null
    this.animTargetX = null
    this.animStartX = null
    this.disableScrollHandler = false
  }

  isTouching = false
  touchTimeout: number | null = null
  handleTouchEvent = () => {
    console.log('touchevent')
    this.cancelGoToNearest()
    this.isTouching = true
    this.resetScrollAnimation()

    if (this.touchTimeout !== null) {
      clearTimeout(this.touchTimeout)
    }
    this.touchTimeout = window.setTimeout(() => {
      this.isTouching = false;
      this.touchTimeout = null;
      if (this.avgV < 0.2) {
        this.invokeGoToNearest(this.avgV)
      }
    }, 150)
  }

  handleTouchEnd = () => {
    // console.log('touchend', this.avgV);
    this.isTouching = false;
    this.invokeGoToNearest(this.avgV)
  }

  avgV = 0
  isLongInvoke = false
  handleScroll = () => {
    const newTime = performance.now()
    const newX = this.list!.scrollLeft
    const v = (newX - this.lastScrollX) / (newTime - this.lastTime)
    this.avgV = (this.avgV + 0.618*v)/1.618 // moving average

    if (this.disableScrollHandler || this.isTouching) {
      // console.log(`REJECTED scroll: disableScrollHandler: ${this.disableScrollHandler}, isTouching: ${this.isTouching}, avgV: ${this.avgV}`)
      this.lastScrollX = newX
      this.lastTime = newTime
      return
    }

    // console.log(`PASSED scroll: avgV: ${this.avgV}, v: ${v}, lastX: ${this.lastScrollX}, newX: ${newX}, lastTime: ${this.lastTime}, newTime: ${newTime}`)

    this.resetScrollAnimation()

    if (Math.abs(this.avgV) > 0.1) {
      this.cancelGoToNearest()
      this.invokeGoToNearest(this.avgV, 1000)
      this.isLongInvoke = true
    } else {
      if (this.isLongInvoke) {
        this.cancelGoToNearest()
        this.isLongInvoke = false
      }
      this.invokeGoToNearest(this.avgV)
    }

    this.lastTime = newTime
    this.lastScrollX = newX
    this.currItemId = this.scrollPosInItemId()

    this.throttledForceUpdate()
  }

  handlePrev = () => {
    const itemWidth = this.getFirstElementWidth()
    // this.currItemId = this.scrollPosInItemId()

    if (this.list && this.currItemId > 0) {
      this.currItemId--
      const nextScrollX = this.currItemId * itemWidth
      this.animateScroll(nextScrollX)

      this.lastScrollX = nextScrollX
      this.forceUpdate()
    }
  }

  handleNext = () => {
    const itemWidth = this.getFirstElementWidth()
    // this.currItemId = this.scrollPosInItemId()

    if (this.list && ((this.currItemId) * itemWidth) < this.obscuredWidth(this.state)) {
      this.currItemId++
      const nextScrollX = this.currItemId * itemWidth
      this.animateScroll(nextScrollX)
      this.lastScrollX = nextScrollX
      this.forceUpdate()
    }
  }

  prevent = (e: MouseEvent) => {
    e.preventDefault()
  }

  render () {
    return (
      <div className={cn(cl.gallery)} id={this.galleryId}>
        {this.props.mainContent &&
          <div className={cn(cl.bigPictre)}>
            {this.props.mainContent}
          </div>
        }
        {this.props.listItemsContent &&
          <div className={cn(cl.thumbnails, { [cl.nomargin]: this.props.containerNoMargin }, { [cl.productList]: this.props.productList })}>
            <div
              ref={ref => this.list = ref}
              className={cn(cl.listItems, this.props.listItemContainerClassName, { [cl.noborder]: this.props.listItemNoBorders, [cl.nomargin]: this.props.listItemNoMargin })}
              id={this.listItemsId}
              onScroll={this.handleScroll}
              onTouchStart={this.handleTouchEvent}
              onTouchMove={this.handleTouchEvent}
              onTouchEnd={this.handleTouchEnd}
            >
              <WasDisplayedIndexContext.Provider value={this.props.displayed ? this.currItemId + this.props.displayed : 0}>
                {this.props.listItemsContent}
              </WasDisplayedIndexContext.Provider>
            </div>
            {this.shouldRenderPrev && <div className={cn(cl.prev, { [cl.disabled]: !this.canScrollPrev})} title="Előző" onClick={this.handlePrev} onMouseDown={this.prevent}><SVGArrow /></div>}
            {this.shouldRenderNext && <div className={cn(cl.next, { [cl.disabled]: !this.canScrollNext})} title="Következő" onClick={this.handleNext} onMouseDown={this.prevent}><SVGArrow /></div>}
          </div>
        }
      </div>
    )
  }
}

export default ScrollableList
