import React, { ReactNode, ReactChild, ReactElement } from 'react'
import InputWrapper from "./InputWrapper"
import Checkbox from './Checkbox'
import Input from './Input'
import DropdownSelect from './Dropdown/DropdownSelect';
import { noError } from 'Utils/helpers/errorHelper';
import FormError from 'Components/FormError';

type FormChangeHandler<T extends Redux.FormJSON = any, U extends keyof T = keyof T> = (name: U, value: T[U], event: React.SyntheticEvent) => void
// export type ValuesType = { [key: string]: any }

type PropType<T extends Redux.FormJSON = Redux.FormJSON> = {
    errors?: Redux.IFormErrors<T>,
    values?: T,
    onChange?: FormChangeHandler<T>,
    [s: string]: any
}

const InputChangeWrapper = (onChange: FormChangeHandler) => (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.name, e.currentTarget.value, e)
const CheckboxChangeWrapper =  (onChange: FormChangeHandler) => (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.name, e.currentTarget.checked, e)

function Form<T extends Redux.FormJSON = any>({ errors = noError(), values = {} as T, onChange = () => {}, ...formProps }: PropType<T>) {
    const addFormPropsToElemProps = (element: ReactElement<any>) => {
        const { type, props: { children, ...elemProps } } = element

        switch (type) {
            case InputWrapper:
                return { ...formProps, error: errors.fieldErrors[elemProps.name], ...elemProps }
            case Input:
                return { ...formProps, error: errors.fieldErrors[elemProps.name], onChange: InputChangeWrapper(onChange), value: values[elemProps.name], ...elemProps }
            case Checkbox:
                return { ...formProps, error: errors.fieldErrors[elemProps.name], onChange: CheckboxChangeWrapper(onChange), checked: values[elemProps.name], ...elemProps }
            case DropdownSelect:
                return { ...formProps, onChange, value: values[elemProps.name], ...elemProps }
            case 'input':
                return { ...formProps, value: values[elemProps.name], onChange: InputChangeWrapper(onChange), ...elemProps }
            default:
                return element.props
        }
    }

    const childrenMapper = (element: ReactChild): ReactChild => {
        if (React.isValidElement<any>(element)) {
            const newProps = addFormPropsToElemProps(element)
            let newChildren
            if (typeof element.props.children === 'function') {
                newChildren = (...args: any[]) => {
                    return React.Children.map(element.props.children(...args), childrenMapper)
                }
            } else {
                newChildren = React.Children.map(element.props.children, childrenMapper)
            }
            return React.cloneElement(element, newProps, newChildren)
        }

        return element
    }

    return (
        < >
            {errors && errors.general && <FormError message={errors.general} errorType={errors.generalErrorType} />}
            {React.Children.map(formProps.children, childrenMapper)}
        </>
    )
}

export default Form
