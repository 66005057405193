import React, { Component, ReactElement } from 'react'
import Head from 'next/head'
import PageLink from 'Components/PageLink'
import AppBar from 'Components/AppBar'
import Button from 'Components/Button'
import ProductCard from 'Components/ProductCard'
import ScrollableProductList from 'Components/ScrollableProductList'
import ProductRangeBanner from 'Components/ProductRangeBanner'
import CustomerServiceBox from 'Components/CustomerServiceBox'
import Shops from 'Components/Shops'
import Footer from 'Components/Footer'
import IndicatorDots from 'Components/IndicatorDots'
import CarouselHandlers from 'Components/CarouselHandlers'

import { SVGArrowBack, SVGPlusOrange } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from 'Scss/pages/Index.scss'
import cat from 'Components/CategoryList/CategoryList.scss'
import { connect, DispatchProp } from 'react-redux'
import appBar from 'Components/AppBar/AppBar.scss'

import btn from 'Components/Button/Button.scss'

import ProductModel, { mockListProduct } from 'Models/Product'
import SignUpForNewsletter from 'Components/SignUpForNewsletter'

import { toggleCart } from 'Utils/redux/actions'
import CategoryList from 'Components/CategoryList'
import { ViewType } from 'Components/AppBar/AppBar'
import { getCategoryIcon } from 'Utils/helpers/productCategoryHelper'
import PromotionBlock from 'Components/PromotionBlock'
import CartPanel from 'Components/CartPanel'
import { ProductListData, productsVariables, products, OrderDirection, ProductOrderField, banners_bannersByPlaceId, PromotionPlace } from 'Utils/api/gql/types'
import { CategoryLink } from 'Components/CategoryLink'
import { Query } from 'react-apollo'
import { StickyContainerFix } from 'Components/StickyContainerFix'
import { productsQuery } from 'Utils/api/gql/queries/products'
import AutoResizeCarousel from 'Components/AutoResizeCarousel'
import { LinkRenderer } from 'Utils/helpers/markDownRenderers';
import { GtmProductListImpression } from 'Utils/gtmClient';
import shuffle from 'lodash/shuffle'
import _app from './_app'
import { GA4ViewItemList } from 'Components/GA4ViewItemList';

type StateType = {
  discountedProducts?: Array<ProductModel>
}

type PropType = {
  session: Redux.IStoreSessionData
  productCategories: Webshop.ICategory[]
  isMobile: boolean,
  isOpenedCartPanel: boolean,
  scheduledData: Redux.IScheduledData
}

class IndexPage extends Component<PropType & DispatchProp, StateType> {
  state: StateType = {}

  toggleBasketBar = () => {
    this.props.dispatch(toggleCart())
  }

  renderProductList(products: Array<ProductListData>, inProgress = false) {
    const { isOpenedCartPanel } = this.props

    return (
      <>
        {!inProgress && <GtmProductListImpression products={products} list="Homepage Discounted Products" />}
        <GA4ViewItemList products={products} loading={!inProgress} ga4Info={{ listName: "Homepage Discounted Products", listId: "homapge-discounted-products" }} />
        <ScrollableProductList isOpenedCartPanel={isOpenedCartPanel} isFetching={inProgress}>
          {shuffle(products).map((product, index) => (
            <div className={`${cg.cell}`} key={`discountedProducts${index}`}>
              <ProductCard
                product={product} view='grid' loading={inProgress}
                analytics={{ position: index }}
              />
            </div>
          ))}
        </ScrollableProductList>
      </>
    )
  }

  renderSlider = (bannerPlaceId: string) => {
    const bannerPlace = this.props.scheduledData.banners.find(item => item.id === bannerPlaceId)
    if (!bannerPlace || bannerPlace.banners.length === 0) {
      return
    }

    return (
      <div className={cn(cl.carousel)}>
        <AutoResizeCarousel loop auto widgets={bannerPlace.banners.length <= 1 ? [] : [IndicatorDots, CarouselHandlers]}>
          {bannerPlace.banners.map((item, index: number) => {
            const imageUrl = this.props.isMobile
              ? item.image_url_mobile
              : item.image_url_desktop
            return (
              <div key={`${bannerPlace}-${index}`}>
                <LinkRenderer href={item.url} target={item.url_target}>
                  <img src={imageUrl} width="100%" height="auto" />
                </LinkRenderer>
              </div>
            )
          })}
        </AutoResizeCarousel>
      </div>
    )
  }

  renderBanner = (banner: banners_bannersByPlaceId, type: 'small' | 'large') => {
    const image = this.props.isMobile ?
      banner.image_url_mobile :
      banner.image_url_desktop

    const className = type === 'small' ? cn(cg.cell, cg.medium6) : cn(cl.homePage__mobileBanner);

    return (
      <div className={className}>
          <LinkRenderer href={banner.url} target={banner.url_target}>
            <img src={image} width="100%" height="auto" />
          </LinkRenderer>
      </div>
    )
  }

  renderBanners = (bannerPlaceId: string, type: 'small' | 'large') => {
    const bannerPlace = this.props.scheduledData.banners.find(item => item.id === bannerPlaceId)
    if (!bannerPlace || bannerPlace.banners.length === 0) {
      return
    }

    return this.renderBanner(bannerPlace.banners[0], type);
  }

  render() {
    const { isOpenedCartPanel } = this.props
    const homepageProductCategories = this.props.productCategories.filter(cat => !cat.is_homepage_prohibited)

    return (
      <div>
        <Head>
          <title>GRoby - az Online Szupermarket - élelmiszer házhozszállítás</title>
          <meta name="description" content="Ingyenes szállítás 29.999 Ft-tól, akár a megrendelés napján. Kiszállítás 500 kg-ig Budapestre és környékére. Cipekedés és sorban állás helyett rendelje házhoz bevásárlását!" />
        </Head>
        <AppBar
          viewType={ViewType.IndexPage}
          pageType="Home"
          breadcrumb="Home"
        />
        <div className={cn(cg.contentHolder, cl.home)}>
          <StickyContainerFix>
            <div className={`${cg.gridContainer} ${cg.panelLayout} ${isOpenedCartPanel === true ? cg.opened : ''}`}>
              <div className={cn(cg.cartOpener, appBar.cartOpener)}>
                <Button
                  label="Kosár tartalma"
                  className={cn(btn.hollow)}
                  disabled={false}
                  renderIcon={{ alignIcon: null, ariaLabel: 'ariaLabel', Icon: <SVGArrowBack width={24} height={24} /> }}
                  onClick={this.toggleBasketBar}
                />
              </div>

              <CartPanel />

              <div className={cn(cg.contentPanel, appBar.contentPanel, cl.contentPanel, cg.mediumOrder1, cg.opened)}>

                {this.renderSlider("1")}

                <CategoryList isOpenedCartPanel={isOpenedCartPanel}>
                  {homepageProductCategories.map(category =>
                    <div className={cn(cat.category)} key={category.id}>
                      <CategoryLink category={category}>
                        <div className={cn(cg.gridX, cg.alignMiddle, cat.catHolder)}>
                          <div className={cn(cg.cell, cg.shrink, cg.hideForLarge)}>
                            {getCategoryIcon(category)}
                          </div>
                          <div className={cn(cg.cell, cg.auto)}>
                            <h3>{category.name}</h3>
                          </div>
                          <div className={cn(cg.cell, cg.shrink, cg.showForLarge)}>
                            <img src={`/static/illustration/${category.iconName}.png`} />
                          </div>
                        </div>
                      </CategoryLink>
                    </div>
                  )}
                </CategoryList>
                {<Query<products, productsVariables>
                  query={productsQuery}
                  partialRefetch={true}
                  variables={{
                    filters: { category: null, is_special: true, remove_homepage_prohibited: true },
                    page: 1,
                    count: 24,
                    sortBy: { direction: OrderDirection.DESC, field: ProductOrderField.SORT },
                    pageURL: _app.getCurrentPath()
                  }}
                >{
                  ({ data, loading }) =>
                    (loading || data && data.products && data.products.products && data.products.products.length)
                    ? <>
                      <div className={cn(cl.homeHeading, cg.gridX)}>
                        <h3 className={cn(cg.cell, cg.auto)}>Ajánlataink</h3>
                        <div className={cn(cg.cell, cg.shrink)}>
                          <PageLink toPage="/ajanlatok">
                            <span className={cn(cl.more)}>
                              <span>Összes <span className={cn(cg.showForMedium)}>megtekintése</span></span>
                              <SVGPlusOrange width={24} height={24} />
                            </span>
                          </PageLink>
                        </div>
                      </div>
                      { !loading && data && data.products && data.products.products
                        ? this.renderProductList(data.products.products, false)
                        : this.renderProductList([...Array(4)].map(() => mockListProduct()), true)
                      }
                    </>
                  : null
                }
                </Query>}

                <ProductRangeBanner productCounts={this.props.scheduledData.productCounts} />

                <PromotionBlock type="twoColumn" blockId={PromotionPlace.PROMO_1} />

                <PromotionBlock type="fourColumn" blockId={PromotionPlace.PROMO_2} />

                <div className={cn(cg.gridX, cg.gridPaddingX, cl.twoBanner)}>
                  {this.renderBanners('2', 'small')}
                  {this.renderBanners('3', 'small')}
                </div>

                <PromotionBlock type="twoColumn" blockId={PromotionPlace.PROMO_3} />

                <div className={cl.homePage__signUpForNewsletter}>
                  <SignUpForNewsletter />
                </div>

                {this.renderBanners('4', 'large')}

                <div className={cn(cg.gridX, cg.gridMarginX, cl.homePage__customerServiceBoxAndShops)}>
                  <div className={cn(cg.cell, cg.medium5, cl.homePage__customerServiceBox)}>
                    <CustomerServiceBox />
                  </div>

                  <div className={cn(cg.cell, cg.medium7, cl.homePage__shops)}>
                    <Shops isInHomePage={true} />
                  </div>
                </div>
              </div>
            </div>
          </StickyContainerFix>
          <Footer />
        </div>
      </div>
    )
  }
}

function mapToStateProps({ session, productCategories, isMobile, isOpenedCartPanel, scheduledData }: Redux.IReduxState): PropType  {
  return { session, productCategories, isMobile, isOpenedCartPanel, scheduledData }
}

export default connect(mapToStateProps)(IndexPage)
