import React, { useEffect, useState } from "react";
import { products_products_products } from "Utils/api/gql/types";
import gtmClientInstance, { ProductListGA4Type } from "Utils/gtmClient";

type Props = {
    loading: boolean;
    products: products_products_products[];
    ga4Info: ProductListGA4Type
};

export const GA4ViewItemList = ({ loading, products, ga4Info }: Props) => {
    const [sentProductIds, setSentProductIds] = useState<string[]>([]);
    useEffect(() => {
        if (products && !loading) {
            const productsToSend = products.filter(p => !sentProductIds.includes(p.id))
            if (productsToSend.length) {
                // gtmClientInstance.sendViewItemListNew(products, ga4Info);
            }
            setSentProductIds(products.map(p => p.id));
        }
    }, [products, loading])
    return null;
}
