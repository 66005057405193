import React from 'react'
import PropTypes from 'prop-types'
import cl from './ScrollableProductList.scss'
import ScrollableList from 'Components/ScrollableList/ScrollableList';
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter';

type PropTypes = {
  isOpenedCartPanel: boolean
  isFetching: boolean
  children: JSX.Element[]
}

class ScrollableProductList extends React.Component<PropTypes> {
  scrollableList: ScrollableList | null = null

  handleTransitionEnd = () => {
    this.scrollableList && this.scrollableList.setSizes()
  }

  componentDidUpdate (prevProps: PropTypes) {
    if (prevProps.children.length !== this.props.children.length) {
      this.forceUpdate()
    }
  }

  componentDidMount () {
    uiEventEmitter.on(UIEvents.CARTPANEL_TRANSITION_END, this.handleTransitionEnd)
  }

  componentWillMount () {
    uiEventEmitter.off(UIEvents.CARTPANEL_TRANSITION_END, this.handleTransitionEnd)
  }

  render () {
    const { children, isOpenedCartPanel, isFetching } = this.props
    let displayed = isOpenedCartPanel ? 4 : 5;
    if (typeof window !== "undefined") {
      const isMobile = window.matchMedia('(max-width: 640px)').matches;
      const isMedium = window.matchMedia('(max-width: 1024px)').matches;
      displayed = isMobile ? 2 : (isMedium ? 3 : (displayed));
    }
    return (
      <ScrollableList
        ref={ref => this.scrollableList = ref}
        listItemsContent={children}
        listItemContainerClassName={`${cl.scrollabeContainer} ${isOpenedCartPanel === true ? cl.quarter : cl.quint}`}
        listItemNoBorders
        listItemNoMargin
        alwaysShowArrows
        isFetching={isFetching}
        displayed={displayed}
      />
    )
  }
}

export default ScrollableProductList
