import React, { SyntheticEvent } from 'react'

import { SVGArrowDown } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './DropdownQuantity.scss'
import { ProductListData } from 'Utils/api/gql/types';

type StateType = {
  height: number | string,
  opened: boolean
}

type PropType = {
  disabled?: boolean,
  opened?: boolean,
  value: Redux.QtyType,
  product: ProductListData,
  onChange: (quantity: Redux.QtyType) => void
}


// TODO: ARIA
export default class DropdownQuantity extends React.Component<PropType, StateType> {
  foldableElement: HTMLUListElement | null = null

  constructor(props: PropType) {
    super(props)
    this.state = {
      height: 0,
      opened: false
    }

    this.handleClick = this.handleClick.bind(this)
    this.setHeight = this.setHeight.bind(this)
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight() {
    this.setState({ height: 'auto' })

    const height = this.foldableElement && this.foldableElement.scrollHeight + 1 || 0

    this.setState({ height })
  }

  handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    this.setState(prevState => ({
      opened: !prevState.opened,
    }))
  }

  onSelect(item: Redux.QtyType) {
    this.props.onChange(item)
    this.setState(prevState => ({
      opened: !prevState.opened,
    }))
  }

  getName(item: Redux.QtyType): string {
    if (item === 'default') {
      return this.props.product.default_quantity_name || ''
    } else {
      return this.props.product.alternative_quantity_name || ''
    }
  }

  render() {
    const { /* children, */ disabled } = this.props
    const { height, opened } = this.state

    return (
      <div className={cn(cl.dropdownContainer, cl.dropdownQuantity, opened && cl.dropdownContainerIsOpened)}>
        <div className={cl.dropdown__toggler}>
          <button
            className={cn(cg.gridX, cg.alignMiddle, cl.arrowContainer)}
            onClick={(e) => { this.handleClick(e) }}
            aria-label="Open"
            tabIndex={-1}
          >
            <span className={cn(cg.cell, cg.auto, cg.textCenter, cl.maxChar)}>
              <span>{this.getName(this.props.value)}</span>
            </span>

            <span className={cn(cg.cell, cg.shrink)} aria-hidden="true">
              <SVGArrowDown width="14" height="14" />
            </span>
          </button>
        </div>

        <ul
          className={cn(cl.foldable, cl.dropdown__pane)}
          ref={(foldableElement) => { this.foldableElement = foldableElement }}
          style={{ height: opened || disabled ? height : 0 }}
          id="cb1-listbox"
          role="listbox"
          aria-label="Aria label"
        >
          <li onClick={(e: SyntheticEvent) => {this.onSelect('default')}}>
            {this.getName('default')}
          </li>
          <li onClick={(e: SyntheticEvent) => {this.onSelect('alternative')}}>
            {this.getName('alternative')}
          </li>
        </ul>

        {opened && <div className={cl.dropdown__overlay} onClick={this.handleClick} />}
      </div>
    )
  }
}
