import gql from 'graphql-tag'
import ProductListData from '../fragments/ProductListData'
import CategoryDataFragment from '../fragments/CategoryDataFragment';

export const promotionCategories = gql`
query PromotionCategories(
    $promotion_places: [PromotionPlace!]
    $filters: ProductFilters
    $sortBy: ProductOrder!
    $count: Int!
    $page: Int!
) {
    promotionCategories(promotion_places:$promotion_places) {
        id
        name
        url_key
        content
        keywords
        description
        is_promotion
        promotion_place
        products(first: $count, page: $page, sortBy: $sortBy, filters: $filters) {
            products {
                ...ProductListData
            }
            paginatorInfo {
                total
            }
        }
        children {
            ...CategoryDataFragment
        }
    }
}
${ProductListData}
${CategoryDataFragment}
`
