import { CartFragment_cart_products } from "Utils/api/gql/types"
import React, { useState } from "react"
import cl from './ProductQuantityInput.scss'
import btnCss from 'Components/Button/Button.scss'
import cn from 'classnames'
import DropdownSelect from "Components/Dropdown/DropdownSelect"
import Input from "Components/Input"
import Button from "Components/Button"

type GiftCardInputProps = {
  cartItem: CartFragment_cart_products
  onSave: (value: GiftInfo) => void
  isFetching: boolean
}

const giftColors = ['Metál-arany', 'Metál-ezüst', 'Metál-kék', 'Metál-piros', 'Metál-zöld']

export type GiftInfo = {
  color: string
  giftCardText: string
}

export function isGiftInfo(x: any): x is GiftInfo {
  return typeof x === 'object' && typeof x.color === 'string' && giftColors.includes(x.color) && typeof x.giftCardText === 'string'
}


const GiftCardInput: React.FunctionComponent<GiftCardInputProps> = ({ cartItem, onSave, isFetching }) => {
  let initialGiftInfo: GiftInfo = { color: giftColors[0], giftCardText: '' }
  try {
    const info = JSON.parse(cartItem.additional_information || '')
    if (isGiftInfo(info)) {
      initialGiftInfo = info
    }
  } catch(e) { } // parse failed, not valid gift info

  const [giftInfo, setGiftInfo] = useState(initialGiftInfo)

  return (
    <div className={cl.giftBasket}>
      <span className={cl.labelTitle}>
        Masni színe
      </span>
      <DropdownSelect
        name="colorSelector"
        placeholder="Masni színe"
        editingDisabled
        value={giftInfo.color}
        items={giftColors.map(color => ({ title: color, value: color }))}
        isNullable={false}
        onChange={(_, value) => { setGiftInfo({ ...giftInfo, color: value }) } }
      />
      <Input
        name="note"
        type="textarea"
        label="Ajándékkártya szövege"
        placeholder="pl: Sok szeretettel születésnapod alkalmából!"
        value={giftInfo.giftCardText}
        onChange={({ currentTarget: { value } }) => { setGiftInfo({ ...giftInfo, giftCardText: value }) }}
      />
      <Button
        label="Mentés"
        onClick={() => { onSave(giftInfo) }}
        disabled={isFetching}
        className={cn(btnCss.secondary, btnCss.expanded)}
      />
    </div>
  )
}

export default GiftCardInput
