import React from 'react'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './ProductQuantityInput.scss'
import DropdownQuantity from 'Components/Dropdown/DropdownQuantity'
import { connect } from 'react-redux'
import { CartFragment_cart_products } from 'Utils/api/gql/types'

import StatefulInput from 'Components/StatefulInput'
import { numberFormatter } from 'Utils/helpers/formatters'
import CartHelper from 'Utils/helpers/cartHelper'
import { ProductQuantityValidator, ProductQuantityEditValidator, commaToPointTransformator } from 'Utils/helpers/validators'
import { ProductQuantityButton } from './ProductQuantityButton'
import GiftCardInput from './GiftCardInput'

export enum ViewType {
  Promo = 'promo',
  Product = 'product',
  ProductPage = 'product-page',
  Cart = 'cart',
  CartTable = 'cart-table',
  ButtonsBottom = 'buttons-bottom'
}

type StateProps = {
  cartStatus: Redux.ICartStatus
}

type StateType = {
  isMobile: boolean
}

type OwnProps = {
  cartItem: CartFragment_cart_products,
  view: ViewType,
  resetOnPropChange?: boolean,
  onChange: (qty: number, type: Redux.QtyType, additionalInfo?: string) => void
}

type PropType = StateProps & OwnProps

function isMobile(): boolean {
  return window.matchMedia('(max-width: 639px)').matches
}


class ProductQuantityInput extends React.Component<PropType, StateType> {
  constructor(props: PropType){
    super(props)
    this.state = {
      isMobile: false
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount () {
    window && window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const mobile = isMobile()
    if (mobile !== this.state.isMobile) {
      this.setState({ isMobile: mobile })
    }
  }

  get qtyType(): Redux.QtyType {
    if (this.props.view === ViewType.Cart) {
      return 'default'
    }

    return CartHelper.getCartItemQtyType(this.props.cartItem)
  }

  get displayQuantity(): number {
    return CartHelper.getCartItemQuantity(this.props.cartItem, this.qtyType)
  }

  get isFetching(): boolean {
    return CartHelper.isProductFetching(this.props.cartItem.product, this.props.cartStatus)
  }

  onChange = (value?: number, additionalInfo?: string) => {
    typeof value === 'undefined' && (value = this.displayQuantity)
    this.props.onChange(value, this.qtyType, additionalInfo)
  }

  handleChange = (strValue: string) => {
    this.onChange(parseFloat(strValue))
  }

  changeQuantity(newQuantityType: Redux.QtyType) {
    if (this.qtyType === newQuantityType) {
      return
    }
    const newQuantity = newQuantityType === 'default'
      ? this.props.cartItem.alternative_quantity_piece || this.props.cartItem.piece
      : this.props.cartItem.piece
    this.props.onChange(newQuantity, newQuantityType)
  }

  getScale(): number {
    const product = this.props.cartItem.product

    if (this.qtyType === 'default') {
      return product.default_quantity_scale || 1
    } else {
      return product.alternative_quantity_scale || 1
    }
  }

  incrementQuantity = () => {
    this.onChange(this.displayQuantity + this.getScale())
  }

  decrementQuantity = () => {
    this.onChange(this.displayQuantity - this.getScale())
  }

  renderQuantityType() {
    const product = this.props.cartItem.product
    if (!product.alternative_quantity_name || this.props.view === ViewType.Cart) {
      return product.default_quantity_name
    }
    return (
      <DropdownQuantity
        product={this.props.cartItem.product}
        value={this.qtyType}
        onChange={(e) => {this.changeQuantity(e)}}
      />
    )
  }

  renderQTYDecreaseForProductView = () => {
    const isDisabled = this.isFetching || ([ViewType.Cart, ViewType.CartTable].includes(this.props.view) && (this.displayQuantity - this.getScale()) <= 0)
    return (
      <div className={cn(cg.small2, cg.mediumShrink, cg.textRight)}>
        <ProductQuantityButton
          type='decrement'
          disabled={isDisabled}
          onClick={this.decrementQuantity}
          style={{marginRight: '5px'}}
        />
      </div>
    )
  }

  renderQTYIncreaseForProductView = () => {
    return (
      <div className={cn(cg.small2, cg.mediumShrink, cg.textLeft)}>
        <ProductQuantityButton
          type='increment'
          disabled={this.isFetching}
          onClick={this.incrementQuantity}
          style={{marginLeft: '5px'}}
        />
      </div>
    )
  }

  renderQTYChangeForCartView = () => {
    const isDecrementDisabled = this.isFetching || (this.displayQuantity - this.getScale()) <= 0

    return (
      <div className={`${cg.cell} ${cg.small6} `}>
        <div className={cn(cg.gridX)}>
          <div className={cn(cg.small6, cg.alignCenter)}>
            <ProductQuantityButton
              type='decrement'
              small
              disabled={isDecrementDisabled}
              onClick={this.decrementQuantity}
            />
          </div>

          <div className={cn(cg.small6, cg.textLeft)}>
            <ProductQuantityButton
              type='increment'
              small
              disabled={this.isFetching}
              onClick={this.incrementQuantity}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { cartItem } = this.props
    const product = cartItem.product
    const quantityInfo = product.alternative_quantity_name
      ? `1 ${product.alternative_quantity_name} = ${product.alternative_quantity_exchange_rate} ${product.default_quantity_name}`
      : ''
    const quantityName = product.default_quantity_name

    const totalInfo = `${numberFormatter(cartItem.piece, 2, true)} ${quantityName} a kosárban`

    return (
      <div className={`${cl.productQuantityContainer} ${this.props.view === ViewType.ProductPage ? cl.product : ''} ${this.props.view === ViewType.Product ? cl.productCard : ''} ${this.props.view === ViewType.Promo ? cl.promo : ''} eee ${this.props.view === ViewType.Cart ? cl.cartbar : ''}`} onClick={(e) => {e.preventDefault()}}>
        {this.props.view === ViewType.ButtonsBottom
          ? <div className={cn(cg.gridX)}>
            <div className={cn(cg.small6, cl.borderedLeft, cl.productQuantityInputWrapper)}>
              <StatefulInput
                type="text"
                transformator={commaToPointTransformator}
                validator={ProductQuantityValidator}
                editValidator={ProductQuantityEditValidator}
                isFetching={this.isFetching}
                resetOnFetchingFinished
                resetOnPropChange
                autoCommit={1500}
                value={this.displayQuantity.toString()}
                onCommit={this.handleChange}
                className={cl.small}
              />
            </div>

            <div className={cn(cg.small6, cl.borderedRight)}>
              {this.renderQuantityType()}
            </div>

            <div className={cn(cg.small6)}>
              {this.renderQTYDecreaseForProductView()}
            </div>

            <div className={cn(cg.small6)}>
              {this.renderQTYIncreaseForProductView()}
            </div>
          </div>
          : <div className={`${cg.gridX} ${cg.alignMiddle} ${this.props.view === ViewType.Cart ? '' : ( this.props.view === ViewType.Promo ? '' : `${cg.alignCenter} ${cg.textCenter}`)} `}>
          <div className={`${cg.cell} ${(this.props.view === ViewType.ProductPage) ? (cg.small12 , cg.medium7) : ''} ${(this.props.view === ViewType.Promo) ? cg.small12 : ''} ${cl.productQuantityInputWrapper}`}>
            <div className={cn(cg.gridX)}>

              {!this.state.isMobile ? (
                <>
                  {(this.props.view === ViewType.Promo || this.props.view === ViewType.Product || this.props.view === ViewType.CartTable) && this.renderQTYDecreaseForProductView()}
                </>
              ) : ('')}

              {(this.state.isMobile && this.props.view === ViewType.Promo) || this.props.view === ViewType.ProductPage ? (
                <>
                  {this.renderQTYDecreaseForProductView()}
                </>
              ) : ('')}

              <div className={`${cg.cell} ${cl.borderedLeft} ${this.props.view === ViewType.Cart ? cl.smallWidth : cl.largeWidth} ${cl.productQuantityInputWrapper} ${this.state.isMobile || this.props.view === ViewType.Cart ? cg.small6 : ""}`}>
                <StatefulInput
                  type="text"
                  transformator={commaToPointTransformator}
                  validator={ProductQuantityValidator}
                  editValidator={ProductQuantityEditValidator}
                  autoCommit={1500}
                  isFetching={this.isFetching}
                  resetOnFetchingFinished
                  resetOnPropChange
                  value={this.displayQuantity.toString()}
                  onCommit={this.handleChange}
                  className={`${this.props.view === ViewType.Cart ? cl.small : ''} `}
                />
              </div>

              <div className={`${this.props.view === ViewType.Cart ? cg.small5 : cg.auto} ${cl.borderedRight} ${cg.cell} ${cl.quantityType} ${this.props.view === ViewType.Cart ? cl.smallHeight : ""} ${this.state.isMobile && this.props.view !== ViewType.Cart ? cg.small6 : ""}`}>
                {this.renderQuantityType()}
              </div>

              {!this.state.isMobile ?  (
                <>
                  {(this.props.view === ViewType.Promo || this.props.view === ViewType.Product || this.props.view === ViewType.CartTable) && this.renderQTYIncreaseForProductView()}
                </>
              ) : ('')}

              {(this.state.isMobile && this.props.view === ViewType.Promo) || this.props.view === ViewType.ProductPage ?  (
                <>
                  {this.renderQTYIncreaseForProductView()}
                </>
              ) : ('')}

              {this.state.isMobile && this.props.view === ViewType.Product ? (
                <>
                  <div className={cn(cl.separator)}></div>
                  <div className={cn(cg.small6)}>
                    {this.renderQTYDecreaseForProductView()}
                  </div>

                  <div className={cn(cg.small6)}>
                    {this.renderQTYIncreaseForProductView()}
                  </div>

                </>
              ) : ('')}

            </div>
          </div>

          {quantityInfo && (
            <div className={` ${cg.cell} ${(this.props.view === ViewType.CartTable) ? (cg.small12, cg.medium5) : ''} ${(this.props.view === ViewType.ProductPage) ? cg.auto : ''} ${(this.props.view === ViewType.Promo) ? (cg.small12, cg.textLeft) : ''} ${(this.props.view === ViewType.ProductPage) ? cg.textRight : ''} ${cl.productQuantityInfo} ${this.props.view === ViewType.Cart ? cg.hide : ''} `}>
              {quantityInfo}
            </div>
          )}

          {this.props.view === ViewType.Cart && this.renderQTYChangeForCartView()}

          {totalInfo && (
            <div className={`${cg.cell} ${cl.productQuantityTotal} ${this.props.view === ViewType.Cart ? cg.hide : ''} `}>
              <div className={`${cg.gridX} ${cg.alignMiddle} ${this.props.view === ViewType.ProductPage ? cl.summaryRow : ''}`}>
                <div className={`${cg.cell} ${this.props.view === ViewType.ProductPage ? cn(cg.small6, cg.textLeft, cl.productInfo) : ''} ${this.props.view === ViewType.Promo ? cg.textLeft : ''}`}>
                  {totalInfo}
                </div>
                {this.props.view === ViewType.ProductPage
                  ? (
                    <div className={cn(cg.cell, cg.small6, cg.textRight, cl.productSummary)}>
                      <span className={cn(cg.showForMedium)}>Összesen: </span>{numberFormatter(cartItem.price * cartItem.piece)} Ft
                    </div>
                  )
                  : ('')}
              </div>
            </div>
          )}

          {this.props.view === ViewType.ProductPage && product.gift_message_available ? (
            <GiftCardInput
              key={product.id}
              cartItem={this.props.cartItem}
              onSave={giftInfo => this.onChange(undefined, JSON.stringify(giftInfo))}
              isFetching={this.isFetching}
            />
          ) : null}
        </div>
        }
      </div>
    )
  }
}


function mapToStateProps({ cartStatus }: Redux.IReduxState): StateProps  {
  return { cartStatus }
}

export default connect(mapToStateProps)(ProductQuantityInput)
