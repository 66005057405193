import { parse, isBefore, startOfDay, isAfter, endOfDay, format } from 'date-fns'
import { getJSONCustomAttribute } from './customAttributeHelpers';
import { products_products_products, ProductAlternativeQuantityName, ProductDefaultQuantityName, ProductStatus } from '../utils/api/gql/types';

type ProductAttributes = {
  description: string,
  imageURL: string,
  urlKey: string,
  giftMessageAvailable: boolean,
  components: string
  nutritionData: string
  storageInformation: string
  defaultQuantityName: string
  pricePerUnit: number
  priceUnit: string
  sliceable: boolean
  brand: number
}

type ProductImages = {
  small: string,
  normal: string,
  thumbnail: string
}

const parseDate = (value: string | null): Date | null => {
  if (value !== null) {
    return parse(value)
  }
  return value
}

const addProductImageBaseURL = (url: string) => {
  if(url !== null) {
    return `${process.env.GWS_PRODUCT_IMAGE_BASEURL}${url}`
  }
  return url
}

function convertMediaGalleryEntryJson(entry: Webshop.IMediaGalleryEntryJSON): Webshop.IMediaGalleryEntry {
    if (!entry.extension_attributes) {
        return {
            ...entry
        }
    }
    return {
        ...entry,
        base_image_url: entry.extension_attributes.base_image_url,
        thumbnail_url: entry.extension_attributes.thumbnail_url,
        small_image_url: entry.extension_attributes.small_image_url
    }
}

export default class ProductModel {
  id: number
  category_ids: Array<number>
  sku: string
  name: string
  price: number
  pricePerUnit: number
  priceUnit: number
  discountedPrice: number
  discountedFrom: Date | null = null
  discountedTo: Date | null = null
  defaultUnit: string
  vat: number
  status: number
  visibility: number
  created_at: string
  updated_at: string
  weight: number
  isMPL: boolean
  isUniquePrice: boolean
  barcodes: Array<string>
  newFrom: Date | null = null
  newTo: Date | null = null
  images: Partial<ProductImages>
  attributes: Partial<ProductAttributes>
  alternativeQuantity: {
    exchangeRate: number,
    minimum: number,
    name: string,
    scale: number
  } | null
  defaultQuantity: {
    minimum: number,
    scale: number,
    name: string
  }
  gallery: Array<Webshop.IMediaGalleryEntry>
  isNewProduct: boolean
  currentPrice: number
  isOrderable: boolean
  isDiscountedNow: boolean
  discountUntilFormatted: string
  discountPercentage: number
  nutritionInfo: {
      gluten: boolean,
      lactose: boolean,
      sugar: boolean
  }
  unitPrice: number
  netPrice: number
  tags: Array<string>
  rawConfig: Webshop.IProductJSON

  private readonly _rawJSON: Webshop.IProductJSON

  constructor (json: Webshop.IProductJSON) {
    this._rawJSON = json
    this.id = json.id
    this.category_ids = []
    // this.category_ids = json.extension_attributes.category_links.map(link => Number(link.category_id))
    this.category_ids = (json.extension_attributes && json.extension_attributes.category_links ? json.extension_attributes.category_links : []).map(link => Number(link.category_id))
    this.sku = json.sku
    this.name = json.name
    this.price = Number(json.price)
    this.status = Number(json.status)
    this.visibility = json.visibility
    this.created_at = json.created_at
    this.updated_at = json.updated_at
    this.weight = json.weight

    const customAttributes = {} as ProductAttributes
    const images = {} as ProductImages
    customAttributes.description = getJSONCustomAttribute(json.custom_attributes, 'description')
    customAttributes.urlKey = getJSONCustomAttribute(json.custom_attributes, 'url_key')
    customAttributes.giftMessageAvailable = getJSONCustomAttribute(json.custom_attributes, 'gift_message_available', false) as boolean
    customAttributes.components = getJSONCustomAttribute(json.custom_attributes, 'components')
    customAttributes.nutritionData = getJSONCustomAttribute(json.custom_attributes, 'nutrition_data')
    customAttributes.storageInformation = getJSONCustomAttribute(json.custom_attributes, 'storage_information')
    customAttributes.priceUnit = getJSONCustomAttribute(json.custom_attributes, 'price_unit')
    customAttributes.defaultQuantityName = getJSONCustomAttribute(json.custom_attributes, 'default_quantity_name')
    customAttributes.pricePerUnit = parseInt(getJSONCustomAttribute(json.custom_attributes, 'price_per_unit'), 10)
    customAttributes.sliceable = getJSONCustomAttribute(json.custom_attributes, 'sliceable') === '1'
    customAttributes.brand = Number(getJSONCustomAttribute(json.custom_attributes, 'brand'))

    images.normal = addProductImageBaseURL(getJSONCustomAttribute(json.custom_attributes, 'image'))
    images.small = addProductImageBaseURL(getJSONCustomAttribute(json.custom_attributes, 'small_image'))
    images.thumbnail = addProductImageBaseURL(getJSONCustomAttribute(json.custom_attributes, 'thumbnail'))

    this.isMPL = getJSONCustomAttribute(json.custom_attributes, 'is_mpl', false) as boolean
    this.isUniquePrice = getJSONCustomAttribute(json.custom_attributes, 'TODO!!!!!!!!! EGYEDI AR', false)
    this.barcodes = (getJSONCustomAttribute(json.custom_attributes, 'barcode', '') as string).split(',').map(x => x.trim());
    this.pricePerUnit = getJSONCustomAttribute(json.custom_attributes, 'price_per_unit', 0) as number
    this.priceUnit = getJSONCustomAttribute(json.custom_attributes, 'price_unit', 0) as number
    this.vat = Number(getJSONCustomAttribute(json.custom_attributes, 'vat', 0))
    this.newFrom = parseDate(getJSONCustomAttribute(json.custom_attributes, 'news_from_date', null))
    this.newTo = parseDate(getJSONCustomAttribute(json.custom_attributes, 'news_to_date', null))
    this.discountedPrice = parseInt(getJSONCustomAttribute(json.custom_attributes, 'special_price', 0), 10)
    this.discountedFrom = parseDate(getJSONCustomAttribute(json.custom_attributes, 'special_from_date', null))
    this.discountedTo = parseDate(getJSONCustomAttribute(json.custom_attributes, 'special_to_date', null))
    this.defaultUnit = getJSONCustomAttribute(json.custom_attributes, 'default_quantity_name', 'db')

    this.alternativeQuantity = getJSONCustomAttribute(json.custom_attributes, 'alternative_quantity_name') ? {
      exchangeRate: parseFloat(getJSONCustomAttribute(json.custom_attributes, 'alt_quant_exchange_rate')),
      minimum: parseFloat(getJSONCustomAttribute(json.custom_attributes, 'alternative_quantity_minimum')),
      name: getJSONCustomAttribute(json.custom_attributes, 'alternative_quantity_name'),
      scale: parseFloat(getJSONCustomAttribute(json.custom_attributes, 'alternative_quantity_scale')),
    } : null

    this.defaultQuantity = {
      minimum: parseFloat(getJSONCustomAttribute(json.custom_attributes, 'default_quantity_minimum')),
      name: getJSONCustomAttribute(json.custom_attributes, 'default_quantity_name'),
      scale: parseFloat(getJSONCustomAttribute(json.custom_attributes, 'default_quantity_scale')),
    }

    this.gallery = json.media_gallery_entries
        .map(m => convertMediaGalleryEntryJson(m))
        .filter(image => image.disabled === false)

    this.attributes = customAttributes
    this.images = images

    this.currentPrice = this.getCurrentPrice()
    this.isNewProduct = this.getIsNewProduct()
    this.isOrderable = this.getIsOrderable()
    this.isDiscountedNow = this.getIsDiscountedNow()
    this.discountUntilFormatted = this.getDiscountUntilFormatted()
    this.discountPercentage = this.getDiscountPercentage()
    this.nutritionInfo = this.getNutritionInfo()
    this.unitPrice = this.getUnitPrice()
    this.netPrice = this.getNetPrice()
    this.tags = this.getTags()
    this.rawConfig = this._rawJSON
  }

  getCustomAttributeValue = (custom_attributes: Array<Webshop.ICustomAttribute>, attributeName: string, defaultValue?: string): string => {
    return getJSONCustomAttribute(custom_attributes, attributeName, defaultValue)
  }

  getIsNewProduct () {
    const now = new Date()
    if(this.newFrom === null) { return false }
    if(this.newTo === null) { return true }
    return isBefore(startOfDay(this.newFrom), now) && isAfter(endOfDay(this.newTo), now)
  }

  getCurrentPrice () {
    if (!this.getIsDiscountedNow() || this.discountedPrice === 0) {
      return this.price
    }
    return this.discountedPrice
  }

  getIsOrderable () {
      return this.status === 1  // TODO ez biztos jo?
  }

  getIsDiscountedNow () {
    const now = new Date()
    if(this.discountedFrom === null) { return false }
    if(this.discountedTo === null) { return true }
    return isBefore(startOfDay(this.discountedFrom), now) && isAfter(endOfDay(this.discountedTo), now)
  }

  // returns something like '07. 12'
  getDiscountUntilFormatted () {
    if (!this.discountedTo) return ''
    return format(this.discountedTo, 'MM. DD')
  }

  getDiscountPercentage () {
    if (!this.isDiscountedNow) return 0

    return Math.round((1 - (this.discountedPrice / this.price)) * 100)
  }

  getNutritionInfo () {
    return {
      gluten: false,
      lactose: false,
      sugar: false
    }
  }

  getUnitPrice () {
    return Math.round(this.pricePerUnit * this.currentPrice)
  }

  getNetPrice () {
    return Math.round(this.currentPrice / (1 + (this.vat / 100)))
  }

  getTags () {
    return ['aaa', 'bbb']
  }
}

export const mockListProduct = (): products_products_products => ({
  __typename: 'Product',
  "id": Math.random().toString(),
  "previous_id": "43468",
  "name": "LOADING",
  "status": ProductStatus.AVAILABLE,
  "url_key": null,
  "smarturl": "43468-parmalat-ivojoghurt-500-g-eper.html",
  "default_category_id": 6,
  "price": 0,
  "price_unit": "liter",
  "price_per_unit": 2,
  "unit_price": 1,
  "special_unit_price": 0,
  "vat": 18,
  "special_price": null,
  "special_from_date": null,
  "special_to_date": null,
  "news_from_date": null,
  "news_to_date": null,
  personal_discount_price: null,
  personal_discount_unit_price: null,
  // "description": "Epres, zsírszegény, savanyú tejkészítmény. Egyedi receptúra alapján, 93% joghurtból készült ízletes, gyümölcsös ivójoghurt. Átlagos tápérték 100 g termékben: Energia: 256 kJ (60 Kcal) Zsír: 0,7 g ebből telített: 0,44 g Szénhidrát: 12 g ebből cukrok: 11,7 g Fehérje: 1,5 g Só: 0,1 g Tárolási információ: hűtve, 4-8°C között tárolandó! Származási hely: Horvátország",
  // "components": "zsírszegény \\*\\*\\*joghurt\\*\\*\\* (93%) \\\\\\[pasztörizált sovány \\*\\*\\*tejsavó\\*\\*\\*, pasztörizált \\*\\*\\*tej\\*\\*\\* (zsírtartalom: 2%), cukor, módosított keményítő, sovány \\*\\*\\*savópor\\*\\*\\* \\*\\*\\*(tej)\\*\\*\\*, zselatin, joghurtkultúra\\\\\\]. eperkészítmény (7%) \\\\\\[eperpüré sűrítményből (34%), szacharóz, glükóz-fruktóz szirup, módosított keményítő, aroma, színezék (kármin), sűrítőanyag (pektin), savanyúságot szabályozó anyag (citromsav)\\\\\\]. Allergének vastagon kiemelve!",
  // "nutrition_data": "",
  "images": null,
  "empties": null,
  // "barcodes": [
  //   "5999561310053",
  //   "5999561310176"
  // ],
  "alternative_quantity_name": ProductAlternativeQuantityName.karton,
  "alternative_quantity_minimum": 1,
  "alternative_quantity_scale": 1,
  "alternative_quantity_exchange_rate": 6,
  "default_quantity_name": ProductDefaultQuantityName.db,
  "default_quantity_minimum": 1,
  "default_quantity_scale": 1,
  "gift_message_available": false,
  "attributes": [],
  "is_mpl": false,
  // "meta_title": null,
  // "meta_description": null,
  // "meta_keywords": null,
  "min_expiry_days": null,
  "package_official_g": null,
  "package_official_ml": 500,
  "sliceable": false,
  "weight": 500,
  ext_id: NaN,
  extra_shipping_days: null
})

// export const mockProduct = () => new ProductModel({
//   "id": Math.floor(Math.random() * 100000000 + 1000000),
//   "sku": Math.floor(Math.random() * 100000000 + 1000000).toString(),
//   "name": "Kalifa Kesudió 100 g natúr",
//   "price": 849,
//   "status": 1,
//   "visibility": 4,
//   "created_at": "2018-11-20 15:07:31",
//   "updated_at": "2018-11-22 18:09:45",
//   "weight": 100,
//   "extension_attributes": {
//       "website_ids": [
//           1
//       ],
//       "category_links": [
//           {
//               "position": 0,
//               "category_id": 108
//           }
//       ]
//   },
//   "tier_prices": [],
//   "custom_attributes": [
//       {
//           "attribute_code": "default_quantity_name",
//           "value": "508"
//       },
//       {
//           "attribute_code": "description",
//           "value": "A kesudióban nagyon sok értékes tápanyag található: foszfor, magnézium, vas, réz, cink, szelén, A- és B-vitaminok, béta-karotin, folsav, hogy csak néhányat említsünk.\\n \\n Külön is kiemelnénk magas kalciumtartalmát, melynek köszönhetően erősíti a csontokat, a hajat és a körmöt.\\n \\n A kesudió ezen kívül tele vannak antioxidáns hatású E-vitaminnal is. Az E-vitamin késlelteti a sejtek öregedését és a szabad gyököktől is véd.\\n \\n Túlnyomórészben telítetlen zsírsavakat tartalmaz, így csökkenti a keringési betegségek kockázatát.\\n \\n \\n \\n Energiatartalom: 2512kJ (604Kcal)/100 g\\n \\n Zsírtartalom: 43,8 g/100 g\\n \\n Tárolási információ: Száraz, hűvös helyen tárolandó.\\n \\n Származási hely: EU\\n \\n "
//       },
//       {
//           "attribute_code": "image",
//           "value": "/1/9/19038_se1_9fed7_back.png"
//       },
//       {
//           "attribute_code": "url_key",
//           "value": "kalifa-kesudio-100-g-natur"
//       },
//       {
//           "attribute_code": "default_quantity_minimum",
//           "value": "1"
//       },
//       {
//           "attribute_code": "small_image",
//           "value": "/1/9/19038_se1_9fed7_back.png"
//       },
//       {
//           "attribute_code": "options_container",
//           "value": "container2"
//       },
//       {
//           "attribute_code": "default_quantity_scale",
//           "value": "1"
//       },
//       {
//           "attribute_code": "thumbnail",
//           "value": "/1/9/19038_se1_9fed7_back.png"
//       },
//       {
//           "attribute_code": "category_ids",
//           "value": [
//               "108"
//           ]
//       },
//       {
//           "attribute_code": "alternative_quantity_name",
//           "value": "503"
//       },
//       {
//           "attribute_code": "components",
//           "value": "kesudióbél.\\n \\n \\n \\n Földimogyorót és egyéb dióféléket is feldolgozó üzemben készült.\\n \\n \\n \\n Allergének vastagon szedve!"
//       },
//       {
//           "attribute_code": "swatch_image",
//           "value": "no_selection"
//       },
//       {
//           "attribute_code": "alt_quant_exchange_rate",
//           "value": "16"
//       },
//       {
//           "attribute_code": "smarturl",
//           "value": "19038-kalifa-kesudio-100-g-natur.html"
//       },
//       {
//           "attribute_code": "alternative_quantity_minimum",
//           "value": "1"
//       },
//       {
//           "attribute_code": "brand",
//           "value": "548"
//       },
//       {
//           "attribute_code": "alternative_quantity_scale",
//           "value": "1"
//       },
//       {
//           "attribute_code": "barcode",
//           "value": "5997956700069"
//       },
//       {
//           "attribute_code": "package_official_g",
//           "value": "100.0000"
//       },
//       {
//           "attribute_code": "vat",
//           "value": "27.0000"
//       },
//       {
//           "attribute_code": "price_unit",
//           "value": "kg"
//       },
//       {
//           "attribute_code": "required_options",
//           "value": "0"
//       },
//       {
//           "attribute_code": "has_options",
//           "value": "0"
//       },
//       {
//           "attribute_code": "tax_class_id",
//           "value": "2"
//       },
//       {
//           "attribute_code": "price_per_unit",
//           "value": "10.0000"
//       },
//       {
//           "attribute_code": "availability",
//           "value": "504"
//       },
//       {
//           "attribute_code": "package_kilogram",
//           "value": "983"
//       },
//       {
//           "attribute_code": "is_mpl",
//           "value": "1"
//       }
//   ],
//   "media_gallery_entries": []
// })
