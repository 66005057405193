import React from 'react'
import Carousel, { CarouselPropType } from 're-carousel'
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter'

export default class AutoResizeCarousel extends React.Component<CarouselPropType> {
    ref: Carousel | null = null
    resizing = false
    animationFrameRequest: number | null = null

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize)
        uiEventEmitter.on(UIEvents.CARTPANEL_TRANSITION_START, this.handleTransitionStart)
        uiEventEmitter.on(UIEvents.CARTPANEL_TRANSITION_END, this.handleTransitionEnd)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize)
        uiEventEmitter.off(UIEvents.CARTPANEL_TRANSITION_START, this.handleTransitionStart)
        uiEventEmitter.off(UIEvents.CARTPANEL_TRANSITION_END, this.handleTransitionEnd)
    }

    doResizeJob = () => {
        if (this.ref) {
            this.ref.hideFrames()
            this.ref.prepareAutoSlide()
        }
        if (this.resizing) {
            requestAnimationFrame(this.doResizeJob)
        }
    }

    handleWindowResize = () => {
        if (this.animationFrameRequest === null) {
            this.animationFrameRequest = requestAnimationFrame(() => {
                this.doResizeJob()
                this.animationFrameRequest = null
            })
        }
    }

    handleTransitionStart = () => {
        this.resizing = true
        requestAnimationFrame(this.doResizeJob)
    }

    handleTransitionEnd = () => {
        this.resizing = false
    }

    render = () =>
        <Carousel ref={ref => { this.ref = ref }} {...this.props} />
}
