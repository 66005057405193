import React, { Fragment, SyntheticEvent, ChangeEvent } from 'react'

import Checkbox from 'Components/Checkbox'
import InputWrapper from 'Components/InputWrapper'
import ExternalLink from 'Components/ExternalLink'
import { SVGPaperPlane, SVGTickTiny } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './SignUpForNewsletter.scss'
import chx from 'Components/Checkbox/Checkbox.scss'
import ButtonCSS from 'Components/Button/Button.scss'
import Button from 'Components/Button'
import { validateEmail } from 'Utils/helpers/validators'
import Form from 'Components/Form';
import { hasErrors, noError } from 'Utils/helpers/errorHelper';


interface StateType {
  name: string,
  email: string,
  accepted: boolean
  "group[3][1]": boolean
  errors: Redux.IFormErrors<StateType>
}

type ErrorType = { [s in keyof StateType]?: s  }

export default class SignUpForNewsletter extends React.Component<{}, StateType> {
  formRef: HTMLFormElement | null = null
  state: StateType = {
    name: '',
    email: '',
    accepted: false,
    "group[3][1]": false,
    errors: noError()
  }

  handleSubmit = async () => {
    if (this.validate()) {
      this.formRef && this.formRef.submit()
    }
  }
  handleChange = (field: keyof StateType) => (e: ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore mert ugy tunik, gyoker meg a typescript ebben a helyzetben...
    this.setState({ [field]: e.currentTarget.value, errors: noError() })
  }
  handleChecked = (field: keyof StateType) => () => {
    //@ts-ignore
    this.setState(state => ({ [field]: !state[field], errors: noError() }))
  }
  validate = (): boolean => {
    const { accepted, name, email } = this.state
    const errors: Redux.IFormErrors<StateType> = { fieldErrors: {} }

    if (!accepted) {
      errors.fieldErrors.accepted = 'Kérünk, fogadd el adatkezelési tájékoztatónkat!'
    }

    if (!name) {
      errors.fieldErrors.name = 'Kötelező mező!'
    }

    if (!validateEmail(email)) {
      errors.fieldErrors.email = 'Hibás e-mail cím!'
    }

    if (hasErrors(errors)) {
      this.setState({ errors })
      return false
    }

    return true
  }
  render() {
    const { accepted, name, email } = this.state

    return (
      <Form
        errors={this.state.errors}
      >
      <form
        ref={ref => this.formRef = ref}
        method="post"
        action={process.env.GWS_MAILCHIMP_SUBSCRIBE_URL}
        noValidate
      >
      <div className={cn(cl.signUpForNewsletter)}>
        <div className={cn(cg.gridX, cg.gridMarginX)}>
          <div className={cn(cg.cell, cg.large4)}>
            <div className={cn(cg.gridX, cg.alignMiddle)}>
              <div className={cn(cg.cell, cg.shrink, cl.illustrationContainer)} aria-hidden="true">
                <SVGPaperPlane width="55" height="55" />
              </div>

              <div className={cn(cg.cell, cg.auto, cl.content)}>
                <h2>Iratkozz fel hírlevelünkre!</h2>

                <ul>
                  <li className={cn(cg.gridX, cg.alignMiddle)}>
                    <span className={cn(cg.cell, cg.shrink, cl.cell, cl.shrink)} aria-hidden="true">
                      <SVGTickTiny width="14" height="14" />
                    </span>

                    <span className={cn(cg.cell, cg.auto)}>Egyedi ajánlatok</span>
                  </li>
                  <li className={cn(cg.gridX, cg.alignMiddle)}>
                    <span className={cn(cg.cell, cg.shrink, cl.cell, cl.shrink)} aria-hidden="true">
                      <SVGTickTiny width="14" height="14" />
                    </span>

                    <span className={cn(cg.cell, cg.auto)}>Újdonságok</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={cn(cg.cell, cg.large8)}>
            <fieldset>
              <div className={cn(cg.gridX, cg.gridMarginX)}>
                <div className={cn(cg.cell, cg.medium6)}>
                  <InputWrapper name="name" label="Név" hideLabel>
                    <input name="NAME" type="text" autoComplete="name" placeholder="Név" value={name} onChange={this.handleChange('name')} />
                  </InputWrapper>
                </div>

                <div className={cn(cg.cell, cg.medium6)}>
                  <InputWrapper name="email" label="E-mail cím" hideLabel>
                    <input name="EMAIL" type="email" autoComplete="email" placeholder="E-mail cím" value={email} onChange={this.handleChange('email')} />
                  </InputWrapper>
                </div>
                {/* anti-bot measures, supposedly */}
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                  <input type="text" name="b_5c055fdbbd78b27d8c167a594_9040c432d3" tabIndex={-1} />
                </div>
              </div>
              <div className={cn(cg.gridX, cg.gridMarginX, cg.alignMiddle)}>
                <div className={cn(cg.cell, cg.medium8, cl.mobileMargin)}>
                  <Checkbox
                    className={chx.style2}
                    name="group[3][1]"
                    label={
                      <Fragment>
                        <span>Hozzájárulok személyre szóló e-mail fogadásához.</span>
                      </Fragment>
                    }
                    value="1"
                    checked={this.state["group[3][1]"]}
                    onChange={this.handleChecked("group[3][1]")}
                  />

                  <Checkbox
                    className={chx.style2}
                    name="accepted"
                    label={
                      <Fragment>
                        <span>Elolvastam és elfogadtam az </span>
                        <span className={cn(cg.whitespaceNowrap)}>
                          <ExternalLink href="/cikk/adatkezelesi-tajekoztato">
                            Adatkezelési tájékoztató
                          </ExternalLink>
                          -ban
                        </span>
                        <span> foglaltakat. * </span>
                      </Fragment>
                    }
                    checked={accepted}
                    onChange={this.handleChecked("accepted")}
                  />

                </div>

                <div className={cn(cg.cell, cg.medium4)}>
                    <Button
                      label="Feliratkozom"
                      className={cn(ButtonCSS.secondary, ButtonCSS.expanded, cl.button)}
                      onClick={this.handleSubmit}
                    />
                    <input type="hidden" value="Subscribe" name="subscribe" />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      </form>
      </Form>
    )
  }
}
