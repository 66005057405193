import gql from 'graphql-tag'
import PaginatorInfoFragment from '../fragments/PaginatorInfoFragment'
import ProductListData from '../fragments/ProductListData'

export const productsQuery = gql`
query products($filters: ProductFilters, $search: String, $sortBy: ProductOrder, $count: Int, $page: Int, $pageURL: String!) {
    products(filters: $filters, search: $search, sortBy: $sortBy, first: $count, page: $page) {
        paginatorInfo {
            ...PaginatorInfoFragment
        }
        aggregations {
            min_price
            max_price
            attributes {
                ...ProductAggregationAttribute
            }
            categories {
                id
                name
                product_count
            }
        }
        products {
            ...ProductListData
        }
    }
    productListAds(url: $pageURL) {
        id
        name
        type
        position
        product {
            ...ProductListData
        }
        image_url_desktop
        image_url_mobile
        banner_url
    }
}

fragment ProductAggregationAttribute on ProductAttribute {
    id
    name
    code
    filterable
    type
    is_active

    options {
        id
        label
        position
        is_active
        is_default
        count
    }
    count
}
${PaginatorInfoFragment}
${ProductListData}
`
