import React from 'react'
import { ButtonProps } from '../Button'
import Button from 'Components/Button'
import cl from './ProductQuantityInput.scss'
import { SVGPlus, SVGMinus } from 'Components/SVGCollection';

type PropType = {
    type: 'increment' | 'decrement'
    small?: boolean
} & Partial<ButtonProps>

export const ProductQuantityButton: React.SFC<PropType> = ({ type, disabled, small, ...restProps }: PropType) => {
    const label = type === 'increment' 
        ? 'Növel' 
        : 'Csökkent'
    const Icon = type === 'increment'
        ? SVGPlus
        : SVGMinus
    return (
        <Button
            className={cl.button}
            renderIcon={{
                ariaLabel: label,
                Icon: <Icon width="24" height="24" className={small ? cl.small : ''} style={{ color: !disabled ? '#F06F13' : '#c6cade' }} />,
            }}
            disabled={disabled}
            {...restProps}
        />
    )
}