import React from 'react'

import cn from 'classnames'
import cl from './CarouselHandlers.scss'
import { SVGArrow } from 'Components/SVGCollection';

type PropType = {
  nextHandler: () => void
  prevHandler: () => void
}

class CarouselHandlers extends React.PureComponent<PropType> {
  render () {
    const { prevHandler, nextHandler } = this.props

    return (
      <div className={cn(cl.carouselHandlers)}>
        <div className={cn(cl.prev)}>
          <SVGArrow onClick={prevHandler} onMouseDown={e => e.preventDefault()} />
        </div>
        <div className={cn(cl.next)}>
          <SVGArrow onClick={nextHandler} onMouseDown={e => e.preventDefault()} />
        </div>
      </div>
    )

  }
}
export default CarouselHandlers
