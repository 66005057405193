import React, { Component, ReactElement, SyntheticEvent } from "react"
import PageLink from "./PageLink"
import ProductList from "./ProductList"
import ProductCard from "./ProductCard"
import Button from "./Button"
import { SVGPlusOrange } from "./SVGCollection"
// import { ProductListHelper } from "Utils/helpers/productListHelper"

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from 'Scss/pages/Index.scss'
import { connect } from "react-redux"

import btn from 'Components/Button/Button.scss'
import ScrollableProductList from "./ScrollableProductList"
import { ProductListData, PromotionCategories_promotionCategories } from "Utils/api/gql/types"
import { promotionCategories } from "Utils/api/gql/queries/promotionCategories";
import promotion from "Pages/promotion";
import gtmClientInstance from "Utils/gtmClient";

type PromotionBlockType = 'twoColumn' | 'fourColumn'

type OwnProps = {
    type: PromotionBlockType
    blockId: string | null
}/* & ({
    promotionCategory?: undefined
    blockId: string
} | {
    blockId?: undefined
    promotionCategory: Webshop.ICategory<PromotionCategories_promotionCategories>
})*/

type StateProps = {
    promotionCategories: Webshop.ICategory<Webshop.RawPromotionCategory>[] | null
    isOpenedCartPanel: boolean
}

type StateType = {
    opened: boolean
    promotionCategories: Webshop.ICategory<Webshop.RawPromotionCategory>[] | null
}

type PropType = OwnProps & StateProps

class PromotionBlock extends Component<PropType, StateType> {
    state: StateType = {
        opened: false,
        promotionCategories: null,
    }

    constructor(props: PropType) {
        super(props);
        const blockId = this.props.blockId
        if (props.promotionCategories) {
            this.state.promotionCategories = props.promotionCategories!.filter((category: Webshop.ICategory<Webshop.RawPromotionCategory>) =>
                (!!blockId ? (category.promotion_place === blockId) : !category.promotion_place))
        }
    }

    renderProductCard(product: ProductListData, i: number, promotionBlockData: PromotionCategories_promotionCategories) {
        const ga4 = { position: i, listName: promotionBlockData.name, listId: promotionBlockData.url_key }
        return (
            <div className={cn(cg.cell, cg.medium6)} key={`promotion-block-${this.props.blockId}-${product.id}`}>
                <ProductCard
                    product={product}
                    view="promo"
                    analytics={{ position: i }}
                />
            </div>
        )
    }

    renderMoreButton() {
        if (!this.state.opened) {
            return (
                <Button
                    style={{marginTop: '10px'}}
                    label="Mutass többet"
                    className={cn(btn.secondary, btn.expanded)}
                    onClick={(e: SyntheticEvent) => {
                        e.preventDefault()
                        this.setState({opened: true})
                    }}
                    disabled={false}
                />
            )
        }
    }

    renderTwoColumn(promotionBlockData: PromotionCategories_promotionCategories) {
        return (
          <div style={{marginBottom: '30px'}} key={promotionBlockData.id}>
            <div className={cn(cl.homeHeading, cg.gridX)}>
              <h3 className={cn(cg.cell, cg.auto)}>{promotionBlockData.name}</h3>
              <div className={cn(cg.cell, cg.shrink)}>
                <PageLink
                    toPage={`/category?categoryId=${promotionBlockData.id}`}
                    as={`/termekvalogatasok/${promotionBlockData.url_key}`}
                >
                    <span className={cn(cl.more)}>
                        <span>Összes <span className={cn(cg.showForMedium)}>megtekintése</span></span>
                        <SVGPlusOrange width={24} height={24} />
                    </span>
                </PageLink>
              </div>
            </div>
            <ProductList gridCols={{ small: 1, medium: 2 }} view="list" >
                {promotionBlockData.products.products!.map((product: ProductListData, index: number) => {
                    const maxItems = this.state.opened ? 2 + 2 : 2
                    if (index < maxItems) {
                        return this.renderProductCard(product, index, promotionBlockData)
                    }
                })}
            </ProductList>

            {/*this.renderMoreButton()*/}

          </div>
        )
    }

    renderFourColumn(promotionBlockData: PromotionCategories_promotionCategories) {
        return (
            <React.Fragment key={promotionBlockData.id}>
                <div className={cn(cl.homeHeading, cg.gridX)}>
                    <h3 className={cn(cg.cell, cg.auto)}>{promotionBlockData.name}</h3>
                    <div className={cn(cg.cell, cg.shrink)}>
                    <PageLink
                        toPage={`/category?categoryId=${promotionBlockData.id}`}
                        as={`/termekvalogatasok/${promotionBlockData.url_key}`}
                    >
                        <span className={cn(cl.more)}>
                        <span>Összes <span className={cn(cg.showForMedium)}>megtekintése</span></span>
                        <SVGPlusOrange width={24} height={24} />
                        </span>
                    </PageLink>
                    </div>
                </div>

                <ScrollableProductList isOpenedCartPanel={this.props.isOpenedCartPanel} isFetching={false}>
                    {promotionBlockData.products.products!.map((product: ProductListData, index: number): ReactElement<any> => (
                        <div className={`${cg.cell}`} key={`promotion-block-${this.props.blockId}-${product.id}`}>
                            <ProductCard
                                product={product} view='grid'
                                analytics={{ position: index }}
                            />
                        </div>
                    ))}
                </ScrollableProductList>
            </React.Fragment>
        )
    }

    componentDidMount = () => {
        if (this.state.promotionCategories) {
            this.state.promotionCategories.forEach(promotionCategory => {
                if (this.props.type === "twoColumn" && promotionCategory.products.products) {
                    const ga4 = { listName: promotionCategory.name, listId: promotionCategory.url_key }
                    gtmClientInstance.sendViewItemListNew(promotionCategory.products.products.slice(0, this.state.opened ? 4 : 2), ga4);
                }
                if (this.props.type === "fourColumn" && promotionCategory.products.products) {
                    const ga4 = { listName: promotionCategory.name, listId: promotionCategory.url_key }
                    gtmClientInstance.sendViewItemListNew(promotionCategory.products.products.slice(0, 4), ga4);
                }
            })
        }
    }

    render() {
        if (!this.state.promotionCategories) {
            return ''
        }

        return this.state.promotionCategories.map(promotionBlockData => {
            if (!(promotionBlockData && promotionBlockData.products.products && promotionBlockData.products.products.length)) {
                return null
              }

              if (this.props.type === 'twoColumn') {
                  return this.renderTwoColumn(promotionBlockData)
              }

              if (this.props.type === 'fourColumn') {
                  return this.renderFourColumn(promotionBlockData)
              }
        })



        // return ''
    }
}

function mapToStateProps({ promotionCategories, isOpenedCartPanel }: Redux.IReduxState): StateProps  {
    return { promotionCategories, isOpenedCartPanel }
}

export default connect(mapToStateProps)(PromotionBlock)
