import React from 'react'
// import PropTypes from 'prop-types'

import cn from 'classnames'
import cl from './IndicatorDots.scss'

function Dot ({ selected }: { selected: boolean }) {
  return (
    <span className={`${cl.dot} ${selected ? cl.selected : ''} `} />
  )
}

type PropType = {
  index: number,
  total: number
}

class IndicatorDots extends React.PureComponent<PropType> {
  render () {
    const { total, index } = this.props
    if (total < 2) {
      return <div className={cn(cl.indicatorDots)} />
    } else {
      return (
        <div className={cn(cl.indicatorDots)}>{
          [...Array(total)].map((x, i) => {
            return <Dot key={i} selected={index === i}  />
          })
        }</div>
      )
    }
  }
}
export default IndicatorDots