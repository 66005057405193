import { CommitType } from "Components/StatefulInput";

export const NumberValidator = (input: string): boolean =>
    !/[xXbBe+-]/.test(input) && !isNaN(Number(input)) && isFinite(Number(input))

export const NumberAndNonNegativeValidator = (input: string): boolean =>
    NumberValidator(input) && Number(input) >= 0

export const ProductQuantityEditValidator = (input: string) =>
    NumberAndNonNegativeValidator(input) && input.length <= 5

export const ProductQuantityValidator = (input: string, type: CommitType) =>
    ProductQuantityEditValidator(input) && (type != 'timeout' || Number(input) > 0) && input !== ''

export function validateEmail(email: string) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

export function commaToPointTransformator(str: string): string {
    return str.replace(/,/gi, '.')
}


