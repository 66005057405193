import React, { ChangeEvent } from 'react'

export type CommitType = 'blur' | 'enter' | 'timeout'

type PropType = {
    value: string
    onCommit: (value: string) => void
    validator?: (value: string, commitType: CommitType) => boolean
    editValidator?: (value: string) => boolean
    transformator?: (value: string) => string
    autoCommit?: number
    resetOnPropChange?: boolean
    resetOnFetchingFinished?: boolean
    isFetching?: boolean
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

type StateType = {
    prevPropValue: string
    prevIsFetching: boolean | undefined
    draftValue: string
}

// It must always update the props mirrored in state
type DerivedStateReturnType = Pick<StateType, 'prevIsFetching' | 'prevPropValue'> & Partial<StateType>

export default class StatefulInput extends React.Component<PropType, StateType> {
    shouldRefocusWhenFetchingFinished = false
    inputRef: HTMLInputElement | null = null
    autoCommitTimeout: number | null = null
    state: StateType = {
        prevPropValue: this.props.value,
        prevIsFetching: this.props.isFetching,
        draftValue: this.props.value
    }

    static getDerivedStateFromProps(props: PropType, state: StateType): DerivedStateReturnType {
        let stateChange: DerivedStateReturnType = {
            prevPropValue: props.value,
            prevIsFetching: props.isFetching
        }

        const shouldUpdateDraftValue =
            props.resetOnPropChange && props.value !== state.prevPropValue ||
            props.resetOnFetchingFinished && state.prevIsFetching && !props.isFetching

        if (shouldUpdateDraftValue) {
            stateChange = { ...stateChange, draftValue: props.value }
        }

        return stateChange
    }

    componentDidUpdate(prevProps: PropType) {
        if (this.shouldRefocusWhenFetchingFinished && prevProps.isFetching && !this.props.isFetching && this.inputRef) {
            this.inputRef.focus()
            this.shouldRefocusWhenFetchingFinished = false
        }
    }

    validateCommit = (type: CommitType): boolean => {
       return !this.props.validator || this.props.validator(this.state.draftValue, type)
    }

    validateEdit = (value: string): boolean => {
        return !this.props.editValidator || this.props.editValidator(value)
    }

    commit = (type: CommitType) => {
        if (this.autoCommitTimeout) {
            window.clearTimeout(this.autoCommitTimeout)
            this.autoCommitTimeout = null
        }
        if (this.validateCommit(type)) {
            if (this.state.draftValue !== this.props.value) {
                if (type === 'timeout') {
                    this.shouldRefocusWhenFetchingFinished = true
                }
                this.props.onCommit(this.state.draftValue)
            } else {
                if (type === 'enter') {
                    this.inputRef && this.inputRef.blur()
                }
            }
        } else {
            if (type === 'blur') {
                this.setState({ draftValue: this.props.value })
            }
        }
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this.commit('enter')
        }
        if (e.key === 'Escape') {
            this.setState({ draftValue: this.props.value }, () => {
                this.inputRef && this.inputRef.blur()
            })
        }
    }

    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = this.props.transformator
            ? this.props.transformator(e.currentTarget.value)
            : e.currentTarget.value

        if (this.autoCommitTimeout) {
            window.clearTimeout(this.autoCommitTimeout)
            this.autoCommitTimeout = null
        }

        if (!this.validateEdit(value)) {
            return
        }

        this.setState({ draftValue: value }, () => {
            if (typeof this.props.autoCommit === 'number') {
                if (this.props.autoCommit === 0) {
                    this.commit('timeout')
                } else {
                    this.autoCommitTimeout = window.setTimeout(() => this.commit('timeout'), this.props.autoCommit)
                }
            }
        })
    }

    render() {
        const {
            value, onCommit, autoCommit, resetOnPropChange, resetOnFetchingFinished,
            isFetching, validator, editValidator, transformator,
            ...restProps
        } = this.props

        return (
            <input
                {...restProps}
                ref={ref => this.inputRef = ref}
                disabled={isFetching}
                value={this.state.draftValue}
                onChange={this.handleChange} onKeyDown={this.handleKeyDown} onBlur={() => this.commit('blur')}
            />
        )
    }
}
