import React, { SyntheticEvent } from 'react'
import Button from 'Components/Button'
import ProductQuantityInput from 'Components/ProductQuantityInput'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './CartListProductCard.scss'
import btn from 'Components/Button/Button.scss'
import { connect, DispatchProp } from 'react-redux'
import { cartRequestProductUpdate } from 'Utils/redux/actions'
import { SVGTrashSmall } from 'Components/SVGCollection'
import { ViewType } from 'Components/ProductQuantityInput/ProductQuantityInput'
import ProductLink from 'Components/ProductLink'
import { CartFragment_cart_products } from 'Utils/api/gql/types'
import { getImageForSizeOrPlaceholder, getCurrentPrice, getOriginalPrice, isDiscounted } from 'Utils/helpers/productAttributeHelper'
import { numberFormatter } from 'Utils/helpers/formatters'

type OwnProps = {
  cartItem: CartFragment_cart_products | Webshop.EmptiesCartProduct,
  className?: string
}

type PropType = OwnProps & DispatchProp

class CartListProductCard extends React.Component<PropType> {

  async removeFromCart(e: SyntheticEvent) {
    e.preventDefault()

    if (this.props.cartItem.__typename !== 'CartProduct') {
      return
    }

    this.props.dispatch(cartRequestProductUpdate({
      newQuantity: 0,
      product: this.props.cartItem.product,
      quantityType: 'default'
    }))
  }

  setQuantity = async (qty: number, type: Redux.QtyType) => {
    if (this.props.cartItem.__typename !== 'CartProduct') {
      return
    }

    this.props.dispatch(cartRequestProductUpdate({
      newQuantity: qty,
      product: this.props.cartItem.product,
      quantityType: type
    }))
  }

  render() {
    const {
      className,
      cartItem,
      dispatch,
      ...otherProps
    } = this.props

    const product = cartItem.__typename === 'CartProduct'
      ? cartItem.product
      : cartItem.empties
    const image = getImageForSizeOrPlaceholder(product, 0, 'thumbnail')
    const originalPrice = cartItem.__typename === 'CartProduct'
      ? cartItem.piece * (getOriginalPrice(cartItem.product) || 0)
      : cartItem.piece * (getOriginalPrice(cartItem.empties) || 0)
    const price = cartItem.__typename === 'CartProduct'
      ? cartItem.piece * getCurrentPrice(cartItem.product)
      : cartItem.piece * getCurrentPrice(cartItem.empties)
    const priceInfo = `${numberFormatter(getCurrentPrice(product))} Ft / ${product.default_quantity_name}`

    return (
      <div className={cn(cl.cartListProductCard)} {...otherProps}>
        <div className={cn(cg.gridX, cl.gridX)}>
          <div className={cn(cg.cell, cg.shrink)}>
              { cartItem.__typename === 'CartProduct'
                ? <div className={cn(cl.productCardImgContainer)}>
                    <ProductLink product={cartItem.product}>
                      <img src={image} alt="" />
                    </ProductLink>
                  </div>
                : ''
              }
          </div>

          <div className={cn(cg.cell, cg.auto, cl.productCardDataWrapper)}>
            <div className={cn(cl.productCardSataName)}>
            { cartItem.__typename === 'CartProduct'
                ? <ProductLink product={cartItem.product}>
                    {product.name}
                  </ProductLink>
                : product.name
            }
            </div>
          </div>

          <div className={cn(cg.cell, cg.shrink, cl.price)}>
            <span className={cn({[cl.discounted]: isDiscounted(product)})}>{numberFormatter(price)} Ft</span><br />
            <span className={cl.priceInfo}>{priceInfo}</span>
          </div>
        </div>
        <div className={cn(cg.gridX, cl.gridX)}>

          { cartItem.__typename === 'CartProduct'
            ? <div className={cn(cg.cell, cg.auto)} style={{paddingLeft: 55}}>
                <ProductQuantityInput
                  cartItem={cartItem}
                  onChange={this.setQuantity}
                  view={ViewType.Cart}
                />
              </div>
            : <div className={cn(cg.cell, cg.shrink, cl.empties)}>{cartItem.piece} {cartItem.empties.default_quantity_name}</div>
          }

          { cartItem.__typename === 'CartProduct' &&
            <div className={cn(cg.cell, cg.shrink)}>
              <Button
                className={cn(btn.hollow, btn.small, cl.delete)}
                disabled={false}
                renderIcon={{ ariaLabel: 'ariaLabel', Icon: <SVGTrashSmall width={21} height={21} title="Törlés" /> }}
                onClick={(e: SyntheticEvent) => { this.removeFromCart(e) }}
              />
            </div>
          }
        </div>
      </div>
    )
    }
}

export default connect()(CartListProductCard)
