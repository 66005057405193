import React from 'react'
import ExternalLink from 'Components/ExternalLink'
import { SVGSupport, SVGTelephoneTiny } from 'Components/SVGCollection'

import cn from 'classnames'
import cl from './CustomerServiceBox.scss'

type PropType = {
  className?: string
}
class CustomerServiceBox extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props)
  }
  render() {
    const {
      className
    } = this.props
    return (
      <div className={`${cl.customerService} ${className ? ` ${className}` : ''}`}>
        <h3>
          Ügyfél&shy;szolgálat{" "}
          <br />
          és rendelés&shy;felvétel
        </h3>
        <p className={cn(cl.light)}>
          Kérdésed van a kiszállítással vagy a megrendeléssel kapcsolatban? <br /> 
          Keresd kollégáinkat, akik készséggel állnak rendelkezésedre!
        </p>
        <p className={cn(cl.big)}>
          <ExternalLink href="mailto:e-shop@groby.hu">
            <span className={cl.underline}>
              e-shop@groby.hu
            </span>
          </ExternalLink>
          <br /><br />
          <ExternalLink href="tel:+3614763688">
            <span className={cl.tel}>
              <SVGTelephoneTiny width="12" height="12" />
              (06 1) 476 36 88
            </span>
          </ExternalLink>
        </p>

        <div className={cl.iconWrapper} aria-hidden={true}>
          <SVGSupport width="36" height="36" />
        </div>
      </div>
    )
  }
}

export default CustomerServiceBox
